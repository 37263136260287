import {
  FilterModel,
  IServerSideGetRowsParams,
  SortModelItem,
} from 'ag-grid-community'

import { api, MerchantServices } from '@shared/api'
interface ServiceResponse {
  list: MerchantServices[]
  pagination: {
    total_count: number
  }
}
const formatFilter = (filterModel: FilterModel) => {
  const filter = Object.entries(filterModel).reduce((acc, [key, value]) => {
    if (value.filterType === 'text') {
      acc[key] = value.filter
    } else if (value.filterType === 'date') {
      acc[key] = {}
      if (value.dateFrom) {
        acc[key]['$gte'] = new Date(value.dateFrom).getTime() / 1000
      }
      if (value.dateTo) {
        const dateTo = new Date(value.dateTo)
        dateTo.setDate(dateTo.getDate() + 1)
        acc[key]['$lte'] = dateTo.getTime() / 1000
      }
    } else if (value.filterType === 'set') {
      if (value.values && value.values.length > 0) {
        acc[key] = value.values.join(',')
      }
    }
    return acc
  }, {})
  return filter
}
const formatSort = (sortModel: SortModelItem[]) => {
  const sort = sortModel.reduce((acc, { colId, sort }) => {
    acc[colId] = sort
    return acc
  }, {})
  return sort
}
interface Params {
  [key: string]: string
}
/**
 * DataSource to get data from the api on server side model
 * @param service the service to use from feathers
 */
function merchantServicesApi(company_id: string, extraParams?: Params) {
  this.getRows = (params: IServerSideGetRowsParams) => {
    const { sortModel, filterModel = {} } = params.request
    const pageSize = params.api.paginationGetPageSize()
    const currentPage = params.api.paginationGetCurrentPage() + 1
    const filter = formatFilter(filterModel)
    const sort = formatSort(sortModel)
    params.api.hideOverlay()
    const queryParameters = {
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
      page: JSON.stringify({
        size: pageSize,
        number: currentPage,
      }),
      ...Object.entries(extraParams || {}).reduce((acc, [key, value]) => {
        acc[key] = String(value)
        return acc
      }, {}),
    }
    const queryString = new URLSearchParams(queryParameters).toString()
    api
      .service('merchants')
      .services(company_id, queryString)
      .then((result: ServiceResponse) => {
        params.success({
          rowData: result.list,
          rowCount: result.pagination.total_count,
        })
        if (!result.list.length) params.api.showNoRowsOverlay()
      })
      .catch(() => {
        params.fail()
      })
  }
}
export default merchantServicesApi
