import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Grid } from '@mui/material'

import { HasPermission, UserNotAllowed } from '@shared/components'
import { useAuthorization, useFtpPortalHubCommunication } from '@shared/hooks'
import { checkSomePermissions } from '@shared/utils'

import AppsSubmittedByMonth from './charts/apps-submitted-by-month/AppsSubmittedByMonth'
import MonthlyResiduals from './charts/monthly-residuals/MonthlyResiduals'
import SettledTransactions from './charts/settled-transactions/SettledTransactions'

enum ChartPermission {
  SETTLED_TRANSACTIONS = 'v1.partner.dashboard.settled-transactions.get',
  SUBMITTED_APPS = 'v1.partner.dashboard.submitted-apps.get',
  MONTHLY_RESIDUALS = 'v1.partner.dashboard.monthly-residuals.get',
}

const useStyles = tss.withName('Dashboard').create(() => ({
  root: {
    paddingBottom: '50px',
  },
  accessDeniedContainer: {
    display: 'grid',
    minHeight: '85vh',
  },
}))

export default function Dashboard() {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { userPermissionSet } = useAuthorization()
  const { setAppBarTitle } = useFtpPortalHubCommunication()

  const [hasSomeChartPermission, setHasSomeChartPermission] =
    useState<boolean>(false)

  useEffect(() => {
    setAppBarTitle(t('partner-portal.dashboard'))
  }, [])

  useEffect(() => {
    if (!userPermissionSet.size) return

    setHasSomeChartPermission(
      checkSomePermissions(
        userPermissionSet,
        ChartPermission.SETTLED_TRANSACTIONS,
        ChartPermission.SUBMITTED_APPS,
        ChartPermission.MONTHLY_RESIDUALS
      )
    )
  }, [userPermissionSet])

  return (
    <section className={classes.root}>
      {hasSomeChartPermission && (
        <Grid container spacing={2}>
          <HasPermission permission={ChartPermission.SETTLED_TRANSACTIONS}>
            <Grid item xs={12} md={12} lg={6}>
              <SettledTransactions />
            </Grid>
          </HasPermission>

          <HasPermission permission={ChartPermission.SUBMITTED_APPS}>
            <Grid item xs={12} md={12} lg={6}>
              <AppsSubmittedByMonth />
            </Grid>
          </HasPermission>

          <HasPermission permission={ChartPermission.MONTHLY_RESIDUALS}>
            <Grid item xs={12} md={12} lg={6}>
              <MonthlyResiduals />
            </Grid>
          </HasPermission>
        </Grid>
      )}

      {!hasSomeChartPermission && (
        <Grid className={classes.accessDeniedContainer}>
          <UserNotAllowed fullHeight={false} />
        </Grid>
      )}
    </section>
  )
}
