import { ColDef, NestedFieldPaths } from 'ag-grid-community'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { HasPermission, UserNotAllowed } from '@shared/components'
import { useEnforceLogin, useFtpPortalHubCommunication } from '@shared/hooks'
import { EnumServiceName, Merchant } from '@shared/types'
import { formatDatetime, formatPhoneNumber, DataSource } from '@shared/utils'

import { mapMerchantStatusCode } from '@/utils/mapping'

const EMPTY_FIELD_PLACEHOLDER = '-'

export default function Merchants() {
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const [search, setSearch] = useState('')
  const { user } = useEnforceLogin()
  const navigate = useNavigate()

  const datasource = useMemo(() => {
    const extraParams = {}

    if (search) {
      extraParams['keyword'] = search
    }

    return new DataSource('merchants', extraParams)
  }, [search])

  const commonTextColumnFilterProps = {
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'equals',
      filterOptions: ['equals', 'contains'],
      maxNumConditions: 1,
    },
    sortable: true,
  }

  const columnDefs: ColDef<Merchant>[] = useMemo(
    () => [
      {
        ...commonTextColumnFilterProps,
        field: 'company_id',
        headerName: t('partner-portal.merchants.company-id'),
      },
      {
        ...commonTextColumnFilterProps,
        field: 'merchant_number',
        headerName: t('partner-portal.merchants.mid'),
      },
      {
        ...commonTextColumnFilterProps,
        field: 'dba_name',
        headerName: t('partner-portal.merchants.dba'),
      },
      {
        field: 'received_ts',
        headerName: t('partner-portal.merchants.received'),
        sortable: true,
        floatingFilter: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          defaultOption: 'greaterThan',
          filterOptions: [
            {
              displayKey: 'greaterThan',
              displayName: 'Greater Than or Equal',
              predicate: ([filterValue], cellValue) =>
                cellValue === null || cellValue >= filterValue,
            },
            {
              displayKey: 'lessThan',
              displayName: 'Less Than or Equal',
              predicate: ([filterValue], cellValue) =>
                cellValue === null || cellValue <= filterValue,
            },
          ],
          inRangeInclusive: true,
          maxNumConditions: 1,
        },
        cellRenderer: (data) => {
          const timestamp = data.value
          return formatDatetime(timestamp, user.tz) || EMPTY_FIELD_PLACEHOLDER
        },
      },
      {
        field: 'status_code',
        headerName: t('partner-portal.merchants.status'),
        floatingFilter: true,
        sortable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [1, 2, 3, 4, 5, 6, 7, 8],
          valueFormatter: (params) => mapMerchantStatusCode(params.value),
        },
        valueGetter: (params) =>
          mapMerchantStatusCode(params.data?.status_code),
      },
      {
        ...commonTextColumnFilterProps,
        field: 'addresses.address_line_1' as NestedFieldPaths<Merchant, any, 1>,
        headerName: t('partner-portal.merchants.address'),
        valueGetter: (params) => {
          if (
            params.data &&
            params.data.addresses &&
            params.data.addresses.length > 0
          ) {
            return params.data.addresses[0].address_line_1
          } else {
            return EMPTY_FIELD_PLACEHOLDER
          }
        },
      },
      {
        ...commonTextColumnFilterProps,
        field: 'addresses.city' as NestedFieldPaths<Merchant, any, 1>,
        headerName: t('partner-portal.merchants.city'),
        valueGetter: (params) =>
          params.data.addresses && params.data.addresses.length > 0
            ? params.data.addresses[0].city
            : EMPTY_FIELD_PLACEHOLDER,
      },
      {
        ...commonTextColumnFilterProps,
        field: 'addresses.state_province' as NestedFieldPaths<Merchant, any, 1>,
        headerName: t('partner-portal.merchants.state'),
        valueGetter: (params) =>
          params.data.addresses && params.data.addresses.length > 0
            ? params.data.addresses[0].state_province
            : EMPTY_FIELD_PLACEHOLDER,
      },
      {
        ...commonTextColumnFilterProps,
        headerName: t('partner-portal.merchants.zip'),
        field: 'addresses.postal_code' as NestedFieldPaths<Merchant, any, 1>,
        valueGetter: (params) =>
          params.data.addresses && params.data.addresses.length > 0
            ? params.data.addresses[0].postal_code
            : EMPTY_FIELD_PLACEHOLDER,
      },
      {
        ...commonTextColumnFilterProps,
        headerName: t('partner-portal.merchants.phone'),
        field: 'customer_service_phone',
        valueFormatter: (params) => {
          return params.value
            ? formatPhoneNumber(params.value)
            : EMPTY_FIELD_PLACEHOLDER
        },
      },
      {
        ...commonTextColumnFilterProps,
        headerName: t('partner-portal.merchants.agent'),
        field: 'agent_id',
      },
    ],
    [user]
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const handleRowClick = useCallback((event) => {
    const rowData = event.data
    const companyId = rowData.company_id
    navigate(`/partner/merchants/${companyId}`)
  }, [])

  useEffect(() => {
    if (!window.location.search) {
      setSearch('')
      return
    }

    const params = new URLSearchParams(window.location.search)
    const search = params.get('keyword')

    setSearch(search)
  }, [])

  useEffect(() => {
    if (!search) {
      setAppBarTitle(
        t('partner-portal.merchants'),
        t('partner-portal.merchants.view-merchants')
      )
      return
    }

    setAppBarTitle(
      `${t('partner-portal.merchants.merchants-search-results')}: ${search}`,
      t('partner-portal.merchants.searching-merchants')
    )
  }, [search])

  useEffect(() => {
    const clearSearch = () => {
      setSearch('')
    }

    window.addEventListener('FTP_CLEAR_SEARCH_BUTTON', clearSearch)

    return () => {
      window.removeEventListener('FTP_CLEAR_SEARCH_BUTTON', clearSearch)
    }
  }, [])

  return (
    <section>
      <HasPermission
        permission="v1.partner.merchants.get"
        unauthorizedComponent={<UserNotAllowed />}
      >
        <PaginationTable<Merchant>
          getRowId={(data) => data.data.company_id}
          columnDefs={columnDefs}
          rowModelType={'serverSide'}
          serverSideDatasource={datasource}
          onRowClicked={handleRowClick}
          defaultColDef={defaultColDef}
          serviceName={EnumServiceName.Merchants}
          guidingId="merchants"
        />
      </HasPermission>
    </section>
  )
}
