import React from 'react'
export const ApplicationIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.0035 15.4162V10.5984C24.0035 9.89141 23.7221 9.21246 23.2219 8.71225L19.4496 4.94001C18.9494 4.43981 18.2705 4.15836 17.5635 4.15836H7.99688C6.52293 4.15836 5.3291 5.35219 5.3291 6.82613V25.5006C5.3291 26.9745 6.52293 28.1684 7.99688 28.1684H16.6669"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.0034 10.8278H18.6679C17.9316 10.8278 17.334 10.2302 17.334 9.49391V4.15836"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.448 19.4163V27.4163"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.6665 23.1974H18.6665"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
