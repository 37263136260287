import { ApplicationTemplate } from '@shared/api'

// TODO: still to be updated when the new endpoint is ready
export const mapPlatformCode = (
  platformCode: ApplicationTemplate['platform_code']
) => platformCode.toString() || '-'

// TODO: still to be updated when the new endpoint is ready
export const mapAppTypeCode = (
  appTypeCode: ApplicationTemplate['application_type_code']
) => {
  switch (appTypeCode) {
    case 0:
      return 'CC'
    case 1:
      return 'ACH'
    case 2:
      return 'CC & ACH'
    default:
      return '-'
  }
}
