import { MerchantEquipment } from '@/pages/merchants/merchant-details/api/merchantEquipmentApi'

export const mapMerchantStatusCode = (status_code: number | undefined) => {
  switch (status_code) {
    case 1:
      return 'App in Process'
    case 2:
      return 'Underwriting'
    case 3:
      return 'Pending'
    case 4:
      return 'Declined'
    case 5:
      return 'Approved'
    case 6:
      return 'Closed'
    case 7:
      return 'Closed by Merchant'
    case 8:
      return 'Closed by Risk'
    default:
      return 'Unknown'
  }
}

export const serviceType = (service_type_code: number | undefined) => {
  switch (service_type_code) {
    case 1:
      return 'Visa/Mastercard'
    case 2:
      return 'PIN-Based Debit'
    case 3:
      return 'American Express'
    case 4:
      return 'Discover ACQ'
    case 5:
      return 'Check Processing'
    case 6:
      return 'Gift Card'
    case 7:
      return 'Gateway'
    case 8:
      return 'Cash Advance'
    case 9:
      return 'EBT'
    case 10:
      return 'Wireless'
    case 11:
      return 'Voyager'
    case 12:
      return 'Wright Express'
    case 13:
      return 'American Express BLUE'
    case 14:
      return 'Epic ACH'
    case 15:
      return 'Epic PayFac'
    case 16:
      return 'Epic Gateway'
    case 17:
      return 'Fortis Gateway'
    default:
      return 'Unknown'
  }
}

export const serviceStatusCode = (status_code: number | undefined) => {
  switch (status_code) {
    case 0:
      return 'Inactive'
    case 1:
      return 'Active'
    default:
      return 'Unknown'
  }
}

export const mapMerchantsEquipmentStatus = (
  status_code: MerchantEquipment['status_code']
) => {
  switch (status_code) {
    case 'A':
      return 'Active'
    case 'I':
      return 'Inactive'
    default:
      return 'Unknown'
  }
}

export const mapMerchantsEquipmentSerialNumber = (
  serial_number: MerchantEquipment['serial_number']
) => {
  switch (serial_number) {
    case '1':
      return 'Serial 1'
    case '2':
      return 'Serial 2'
    case '3':
      return 'Serial 3'
    case '4':
      return 'Serial 4'
    default:
      return 'Unknown'
  }
}
