import { useEffect } from 'react'
import { tss } from 'tss-react/mui'

import {
  Step,
  StepConnector,
  StepConnectorProps,
  StepIconProps,
  StepLabel,
  Stepper,
  Theme,
  stepConnectorClasses,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import AppTheme from '@shared/design'

type Step = { label: string; number: number }

const useAppStatusStepperStyles = tss
  .withName('AppStatusStepper')
  .create(() => ({
    container: { maxWidth: '1000px' },
    stepLabel: { display: 'inline-block', textAlign: 'center' },
  }))

interface AppStatusStepperProps {
  statusId: number
}

export const AppStatusStepper: React.FC<AppStatusStepperProps> = ({
  statusId,
}) => {
  const { classes } = useAppStatusStepperStyles(AppTheme)

  const steps: Step[] = [
    { label: 'New by Agent', number: 1 },
    { label: 'Ready to Send', number: 2 },
    { label: 'Sent To Merchant', number: 3 },
    { label: 'Merchant Started', number: 4 },
    { label: 'Merchant Complete', number: 5 },
  ]

  const stepByStatusId: Record<number, number> = {
    1: 0,
    3: 1,
    5: 2,
    10: 3,
    15: 4,
  }

  const isBelowMDBreakpoint = useMediaQuery((theme: Theme) =>
    theme?.breakpoints.down('lg')
  )

  const matchesFirstStepperMargin = useMediaQuery(
    '(min-width: 800px) and (max-width: 1144px)'
  )
  const matchesSecondStepperMargin = useMediaQuery('(min-width: 1145px)')

  const getStepperMargin = () => {
    if (matchesFirstStepperMargin) return '50px 0 50px -35px'
    if (matchesSecondStepperMargin) return '50px 0 50px -50px'
    return '50px 0 50px -15px'
  }

  return (
    <Stepper
      className={classes.container}
      style={{ margin: getStepperMargin() }}
      alternativeLabel
      activeStep={stepByStatusId[statusId]}
      connector={<CustomStepConnector />}
    >
      {steps.map(({ label, number }) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={(props: StepIconProps) => (
              <StepIcon {...props} stepNumber={number} />
            )}
          >
            <span
              className={classes.stepLabel}
              style={{ maxWidth: isBelowMDBreakpoint ? '80px' : 'unset' }}
            >
              {label}
            </span>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

const useStepIconStyles = tss.withName('StepIcon').create(({ theme }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
    borderRadius: '100%',
  },
  stepNumber: { color: 'white' },
  stepNumberBorder: {
    position: 'absolute',
    height: '40px',
    width: '40px',
    padding: '24px',
    border: `2px solid ${theme.palette['stacked-blue-85']}`,
    borderRadius: '100%',
  },
}))

const StepIcon: React.FC<StepIconProps & { stepNumber: number }> = ({
  active,
  completed,
  stepNumber,
}) => {
  const { classes, theme } = useStepIconStyles(AppTheme)

  const getColor = () => {
    if (completed) {
      return theme.palette['balance-blue-85']
    }

    if (active) {
      return theme.palette['stacked-blue-85']
    }

    return theme.palette['level-gray']
  }

  const color = getColor()

  return (
    <div
      className={classes.container}
      style={{
        background: color,
        color,
      }}
    >
      {active && <div className={classes.stepNumberBorder}></div>}
      <span className={classes.stepNumber}>{stepNumber}</span>
    </div>
  )
}

const CustomStepConnector = styled((props: StepConnectorProps) => {
  const theme = useTheme()

  // Set background style to first disabled connector.
  useEffect(() => {
    const firstDisabledConnector = document.querySelector<HTMLSpanElement>(
      `.${stepConnectorClasses.disabled}:nth-of-type(1) .${stepConnectorClasses.line}`
    )

    if (firstDisabledConnector) {
      firstDisabledConnector.style.background = `linear-gradient(90deg, ${theme.palette['stacked-blue-85']} 50%, ${theme.palette['level-gray']} 50%)`
    }
  }, [])

  return <StepConnector {...props} />
})(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
    margin: '0 5px',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: `linear-gradient(90deg, ${theme.palette['balance-blue-85']} 50%, ${theme.palette['stacked-blue-85']} 50%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette['balance-blue-85'],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    background: theme.palette['level-gray'],
    borderRadius: 1,
  },
}))
