import { DateTime } from 'luxon'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  LOCAL_STORAGE_PASSWORD_EXP_TS_KEY,
  useNotification,
} from '@shared/hooks'
import { formatDate } from '@shared/utils'

const PasswordExpirationCheck = () => {
  const { t } = useTranslation()
  const { setNotification } = useNotification()

  useEffect(() => {
    const stringTs = localStorage.getItem(LOCAL_STORAGE_PASSWORD_EXP_TS_KEY)

    if (!stringTs) {
      return
    }

    const timestamp = parseInt(stringTs)

    const daysUntilExpiration = parseInt(
      DateTime.fromSeconds(timestamp).diffNow('days').days.toFixed(0)
    )

    const formattedDate = formatDate(timestamp)

    if (formattedDate !== '-' && daysUntilExpiration <= 14) {
      setNotification({
        type: 'info',
        label: t('common.password-will-expire-on', {
          date: formattedDate,
          interpolation: { escapeValue: false },
        }),
        duration: 15000,
      })
    }

    localStorage.removeItem(LOCAL_STORAGE_PASSWORD_EXP_TS_KEY)
  }, [t, setNotification])

  return null
}

export default PasswordExpirationCheck
