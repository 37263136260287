import { yupResolver } from '@hookform/resolvers/yup'
import { TFunction } from 'i18next'
import { FC, useEffect, useState } from 'react'
import {
  FormProvider,
  FormState,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { AppBar } from '@mui/material'

import {
  Button,
  ButtonBar,
  ButtonBarEnd,
  PageLayoutContainerMain,
} from '@shared/components'
import { removeStashedItemGroup } from '@shared/utils/local-storage-stash/localStorageStash'

import { getCCPaymentAndVelocityTotalPercent } from '@/utils/application-templates/getCCPaymentAndVelocityTotalPercent'
import {
  getPaymentAndVelocityFieldLocalStorageKey,
  PAYMENT_AND_VELOCITY_FIELD_LOCAL_STORAGE_KEY_PREFIX,
} from '@/utils/application-templates/getPaymentAndVelocityFieldLocalStorageKey'
import { getTemplateInfoFieldLocalStorageKey } from '@/utils/application-templates/getTemplateInfoFieldLocalStorageKey'

import { ApplicationTypeCode } from '../../application-templates-template-info/ApplicationTemplatesTemplateInfo'
import { ACHPaymentAndVelocityFields } from '../ach-payment-and-velocity-fields/ACHPaymentAndVelocityFields'
import { CCPaymentAndVelocityFields } from '../cc-payment-and-velocity-fields/CCPaymentAndVelocityFields'

const showCCFields = (applicationTypeCode: ApplicationTypeCode) =>
  applicationTypeCode === ApplicationTypeCode.CC ||
  applicationTypeCode === ApplicationTypeCode['ACH-CC']

const showACHFields = (applicationTypeCode: ApplicationTypeCode) =>
  applicationTypeCode === ApplicationTypeCode.ACH ||
  applicationTypeCode === ApplicationTypeCode['ACH-CC']

const buildCCPaymentAndVelocitySchema = (t: TFunction) => {
  const isValidPercent = (percent) =>
    percent !== '' && Number(percent) >= 0 && Number(percent) <= 100

  const whenPercentFieldChangesOptions = {
    is: (value) => !isValidPercent(value),
    then: (schema: yup.StringSchema) => {
      const validationMessage = t(
        'partner-portal.application-templates.you-must-enter-a-value-between-0-and-100'
      )

      return schema
        .test({
          message: validationMessage,
          test: isValidPercent,
        })
        .required(validationMessage)
    },
  }

  // See: https://github.com/jquense/yup/issues/193#issuecomment-437878160
  const schemaCyclicDependenciesKeys: [string, string][] = [
    ['swiped_percent', 'swiped_percent'],
    ['keyed_percent', 'keyed_percent'],
    ['ecommerce_percent', 'ecommerce_percent'],
  ]

  return yup.object().shape(
    {
      swiped_percent: yup
        .string()
        .when('swiped_percent', whenPercentFieldChangesOptions),
      keyed_percent: yup
        .string()
        .when('keyed_percent', whenPercentFieldChangesOptions),
      ecommerce_percent: yup
        .string()
        .when('ecommerce_percent', whenPercentFieldChangesOptions),

      cc_monthly_volume: yup.string(),
      cc_high_ticket: yup.string(),
      cc_average_ticket: yup.string(),

      addon_amex_direct: yup.boolean(),
      addon_discover_direct: yup.boolean(),
      addon_pin_debit: yup.boolean(),
      addon_wireless: yup.boolean(),
      addon_gateway: yup.boolean(),
      addon_clover: yup.boolean(),
      addon_insights: yup.boolean(),
      addon_payeezy: yup.boolean(),
    },
    schemaCyclicDependenciesKeys
  )
}

const buildACHPaymentAndVelocitySchema = (_: TFunction) =>
  yup.object().shape({
    ec_monthly_volume: yup.string(),
    ec_average_ticket: yup.string(),
    high_transaction: yup.string(),

    ec_debit_max_per_ticket: yup.string(),
    ec_debit_max_daily_count: yup.string(),
    ec_debit_max_daily_amount: yup.string(),
    ec_debit_max_14day_count: yup.string(),
    ec_debit_max_14day_amount: yup.string(),

    ec_credit_max_per_ticket: yup.string(),
    ec_credit_max_daily_count: yup.string(),
    ec_credit_max_daily_amount: yup.string(),
    ec_credit_max_14day_count: yup.string(),
    ec_credit_max_14day_amount: yup.string(),

    ccd: yup.boolean(),
    ppd: yup.boolean(),
    web: yup.boolean(),
    tel: yup.boolean(),
  })

const buildSchema = (
  t: TFunction,
  applicationTypeCode: ApplicationTypeCode
) => {
  let schema = yup.object()

  if (showCCFields(applicationTypeCode)) {
    schema = schema.concat(buildCCPaymentAndVelocitySchema(t))
  }

  if (showACHFields(applicationTypeCode)) {
    schema = schema.concat(buildACHPaymentAndVelocitySchema(t))
  }

  return schema
}

export type CCPaymentAndVelocityData = yup.InferType<
  ReturnType<typeof buildCCPaymentAndVelocitySchema>
>

export type ACHPaymentAndVelocityData = yup.InferType<
  ReturnType<typeof buildACHPaymentAndVelocitySchema>
>

export type Payload = CCPaymentAndVelocityData & ACHPaymentAndVelocityData

const getDefaultValues = (
  applicationTypeCode: ApplicationTypeCode
): Partial<Payload> => {
  const ccPaymentAndVelocityDefaultValues: Partial<CCPaymentAndVelocityData> =
    showCCFields(applicationTypeCode)
      ? {
          swiped_percent: '0',
          keyed_percent: '0',
          ecommerce_percent: '0',

          cc_monthly_volume: '0',
          cc_high_ticket: '0',
          cc_average_ticket: '0',

          addon_amex_direct: false,
          addon_discover_direct: false,
          addon_pin_debit: false,
          addon_wireless: false,
          addon_gateway: false,
          addon_clover: false,
          addon_insights: false,
          addon_payeezy: false,
        }
      : {}

  const achPaymentAndVelocityDefaultValues: Partial<ACHPaymentAndVelocityData> =
    showACHFields(applicationTypeCode)
      ? {
          ec_monthly_volume: '0',
          ec_average_ticket: '0',
          high_transaction: '0',

          ec_debit_max_per_ticket: '0',
          ec_debit_max_daily_count: '0',
          ec_debit_max_14day_amount: '0',
          ec_debit_max_14day_count: '0',
          ec_debit_max_daily_amount: '0',

          ec_credit_max_per_ticket: '0',
          ec_credit_max_daily_count: '0',
          ec_credit_max_daily_amount: '0',
          ec_credit_max_14day_count: '0',
          ec_credit_max_14day_amount: '0',

          ccd: true,
          ppd: true,
          web: true,
          tel: false,
        }
      : {}

  return {
    ...ccPaymentAndVelocityDefaultValues,
    ...achPaymentAndVelocityDefaultValues,
  }
}

const getDirtyFields = (
  payload: Payload,
  dirtyFields: FormState<Payload>['dirtyFields']
): Partial<Payload> => {
  return Object.fromEntries(
    Object.entries(payload).filter(([key]) => dirtyFields[key])
  ) as Partial<Payload>
}

const useStyles = tss.withName('CCACHPaymentAndVelocityForm').create(() => ({
  appBar: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
  },
}))

export interface CCACHPaymentAndVelocityFormProps {
  applicationTypeCode: ApplicationTypeCode
}

export const CCACHPaymentAndVelocityForm: FC<
  CCACHPaymentAndVelocityFormProps
> = ({ applicationTypeCode }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { templateId } = useParams()
  const navigate = useNavigate()

  const schema = buildSchema(t, applicationTypeCode)

  const formMethods = useForm<Payload>({
    defaultValues: getDefaultValues(applicationTypeCode),
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  const {
    watch,
    formState: { dirtyFields },
  } = formMethods

  const { swiped_percent, keyed_percent, ecommerce_percent } = watch()

  const [isInvalidTotalPercent, setIsInvalidTotalPercent] = useState(false)

  const removeStashedFields = () =>
    removeStashedItemGroup(PAYMENT_AND_VELOCITY_FIELD_LOCAL_STORAGE_KEY_PREFIX)

  const onSubmit: SubmitHandler<Payload> = (payload) => {
    const data = getDirtyFields(payload, dirtyFields)

    const isInvalidTotalPercent = Boolean(
      (data['swiped_percent'] ||
        data['keyed_percent'] ||
        data['ecommerce_percent']) &&
        getCCPaymentAndVelocityTotalPercent({
          swiped_percent,
          keyed_percent,
          ecommerce_percent,
        }) !== '100'
    )

    setIsInvalidTotalPercent(isInvalidTotalPercent)

    if (isInvalidTotalPercent) {
      return
    }

    const entries = Object.entries(data)

    if (!entries.length) {
      // eslint-disable-next-line
      console.log('No Dirty Fields...')
    } else {
      entries.forEach(([field, value]) => {
        const key = getPaymentAndVelocityFieldLocalStorageKey(field)
        localStorage.setItem(key, String(value))

        // eslint-disable-next-line
        console.log(
          `Field: ${field}\n` +
            `Local Storage Key: ${key}\n` +
            `Value: ${localStorage.getItem(key)}`
        )
      })
    }

    navigate(`/partner/application-templates/${templateId}/product`)
  }

  useEffect(() => {
    removeStashedFields()
  }, [])

  return (
    <PageLayoutContainerMain isFullWidth>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          {showCCFields(applicationTypeCode) && (
            <CCPaymentAndVelocityFields
              isInvalidTotalPercent={isInvalidTotalPercent}
            />
          )}

          {showACHFields(applicationTypeCode) && (
            <ACHPaymentAndVelocityFields />
          )}

          <AppBar className={classes.appBar}>
            <ButtonBar style={{ marginBottom: '0 !important' }}>
              <ButtonBarEnd>
                <Button
                  label={t('partner-portal.application-templates.quit')}
                  color="secondary"
                  style={{ marginRight: '16px' }}
                  onClick={() => {
                    removeStashedFields()
                    navigate('/partner/application-templates')
                  }}
                />

                <Button
                  testId="submit-button"
                  type="submit"
                  label={t(
                    'partner-portal.application-templates.save-and-continue'
                  )}
                />
              </ButtonBarEnd>
            </ButtonBar>
          </AppBar>
        </form>
      </FormProvider>
    </PageLayoutContainerMain>
  )
}
