export const getCCPaymentAndVelocityTotalPercent = ({
  swiped_percent,
  keyed_percent,
  ecommerce_percent,
}: {
  swiped_percent: string
  keyed_percent: string
  ecommerce_percent: string
}) => {
  const stringToNumber = (string) =>
    !isNaN(Number(string)) ? Number(string) : 0

  return String(
    stringToNumber(swiped_percent) +
      stringToNumber(keyed_percent) +
      stringToNumber(ecommerce_percent)
  )
}
