import { ApplicationsSubmitted } from '@shared/api/src/schemas/types'

export const getTotalAppsSubmitted = (
  appsSubmittedByMonth: ApplicationsSubmitted
) => {
  const last12MonthsData = appsSubmittedByMonth

  return last12MonthsData.values.reduce((total, { values }) => {
    values.forEach(([value]) => (total += value))
    return total
  }, 0)
}
