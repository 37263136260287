import { ColDef } from 'ag-grid-community'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { HasPermission, UserNotAllowed } from '@shared/components'

import {
  mapMerchantsEquipmentStatus,
  mapMerchantsEquipmentSerialNumber,
} from '@/utils/mapping'

import {
  MerchantEquipment,
  MerchantEquipmentDataSource,
} from '../../api/merchantEquipmentApi'

const useStyles = tss.withName('Equipment').create(() => ({
  grid: {
    background: '#FFF',
    padding: 20,
    border: 'none',
    paddingBottom: 40,
  },
}))

interface EquipmentProps {
  id: string
}

export function Equipment({ id }: EquipmentProps) {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const datasource = useMemo(() => {
    return new MerchantEquipmentDataSource()
  }, [])

  // const commonTextColumnFilterProps: ColDef<MerchantEquipment> = useMemo(
  //   () => ({
  //     floatingFilter: true,
  //     filter: 'agTextColumnFilter',
  //     filterParams: {
  //       defaultOption: 'contains',
  //       filterOptions: ['contains'],
  //       maxNumConditions: 1,
  //     },
  //     sortable: true,
  //   }),
  //   []
  // )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const columnDefs: ColDef<MerchantEquipment>[] = useMemo(
    () => [
      {
        field: 'model',
        headerName: t('partner-portal.merchant-equipment.model'),
        // ...commonTextColumnFilterProps,
      },
      {
        field: 'status_code',
        headerName: t('partner-portal.merchant-equipment.status'),
        valueGetter: ({ data }) =>
          mapMerchantsEquipmentStatus(data?.status_code),
        // floatingFilter: true,
        // filter: 'agSetColumnFilter',
        // filterParams: {
        //   values: ['A', 'I'],
        //   valueFormatter: ({ value }) => mapMerchantsEquipmentStatus(value),
        // },
      },
      {
        field: 'serial_number',
        headerName: t('partner-portal.merchant-equipment.serial-number'),
        valueGetter: ({ data }) =>
          mapMerchantsEquipmentSerialNumber(data?.serial_number),
        // floatingFilter: true,
        // filter: 'agSetColumnFilter',
        // filterParams: {
        //   values: ['1', '2', '3', '4'],
        //   valueFormatter: ({ value }) =>
        //     mapMerchantsEquipmentSerialNumber(value),
        // },
      },
      {
        field: 'terminal_id',
        headerName: t('partner-portal.merchant-equipment.terminal-id'),
        // ...commonTextColumnFilterProps,
      },
      {
        field: 'merchant_id',
        headerName: t('partner-portal.merchant-equipment.mid/v#'),
        // ...commonTextColumnFilterProps,
      },
      {
        field: 'platform',
        headerName: t('partner-portal.merchant-equipment.platform'),
        // ...commonTextColumnFilterProps,
      },
      {
        field: 'program',
        headerName: t('partner-portal.merchant-equipment.program'),
        // ...commonTextColumnFilterProps,
      },
    ],
    []
  )

  // const handleRowClick = useCallback(
  //   (event: RowClickedEvent<MerchantEquipment>) => {
  //     navigate(`/partner/merchants/equipment/${event.data.equipment_id}`)
  //   },
  //   []
  // )

  return (
    <section>
      <HasPermission
        allPermissions={[
          'v1.partner.merchants.get',
          'v1.partner.merchants.get.equipment',
        ]}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <div className={`ag-theme-alpine ${classes.grid}`}>
          <PaginationTable<MerchantEquipment>
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            getRowId={(data) => data.data.equipment_id}
            guidingId="merchants-details-equipment"
            // onRowClicked={handleRowClick}
            // serviceName={EnumServiceName.Equipment} Add later when we have a service for it
          />
        </div>
      </HasPermission>
    </section>
  )
}
