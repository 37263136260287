import { ColDef } from 'ag-grid-community'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { HasPermission, UserNotAllowed, ThreeDotMenu } from '@shared/components'
import AppTheme from '@shared/design'
import { useEnforceLogin, useFtpPortalHubCommunication } from '@shared/hooks'
import { MerchantServices } from '@shared/types'
import { formatDatetime } from '@shared/utils'

import { serviceStatusCode, serviceType } from '@/utils/mapping/merchants'

import MerchantServicesApi from '../../api/merchantServicesApi'

const useStyles = tss.withName('ServicesTable').create(() => ({
  grid: {
    background: '#FFF',
    padding: 20,
    border: 'none',
    paddingBottom: 40,
    width: '100%',
  },
}))

export function Services(companyId: any) {
  const { t } = useTranslation()
  const { classes } = useStyles(AppTheme)
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()

  const columnDefs: ColDef<any>[] = useMemo(
    () => [
      {
        field: 'service_type_code',
        headerName: t('partner-portal.merchant-services-data.service'),
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
          valueFormatter: (params) => serviceType(params.value),
          comparator: (a, b) => {
            const optionA = serviceType(a)
            const optionB = serviceType(b)

            return optionA.localeCompare(optionB)
          },
          suppressMiniFilter: true,
        },
        sortable: true,
        valueGetter: (params) => serviceType(params.data?.service_type_code),
      },
      {
        field: 'provider_name',
        headerName: t('partner-portal.merchant-services-data.provider'),
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['equals'],
        },
        sortable: true,
      },
      {
        field: 'account_number',
        headerName: t('partner-portal.merchant-services-data.account-number'),
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['equals'],
        },
        sortable: true,
      },
      {
        field: 'status_code',
        headerName: t('partner-portal.merchant-services-data.status'),
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [0, 1],
          valueFormatter: (params) => serviceStatusCode(params.value),
          comparator: (a, b) => {
            const optionA = serviceStatusCode(a)
            const optionB = serviceStatusCode(b)

            return optionA.localeCompare(optionB)
          },
          suppressMiniFilter: true,
        },
        sortable: true,
        valueGetter: (params) => serviceStatusCode(params.data?.status_code),
      },
      {
        filter: 'agDateColumnFilter',
        headerName: t('partner-portal.merchant-services-data.last-modified'),
        field: 'last_modified_ts',
        floatingFilter: true,
        filterParams: {
          filterOptions: ['equals'],
        },
        cellRenderer: (params) => {
          const formattedDate = formatDatetime(params.value, user.tz) || '-'

          return <span>{formattedDate}</span>
        },
      },
      {
        colId: 'threeDots',
        suppressSizeToFit: true,
        lockPinned: true,
        cellRenderer: ({ data }) => {
          const handleEditForRow = () => {}

          const handleDeleteForRow = () => {}

          const options = [
            {
              label: t('common.edit'),
              action: handleEditForRow,
              enabled: true,
            },
            {
              label: t('common.delete'),
              action: handleDeleteForRow,
              enabled: true,
            },
          ]

          return <ThreeDotMenu options={options} />
        },
      },
    ],
    []
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
    }),
    []
  )

  useEffect(() => {
    setAppBarTitle(
      t('partner-portal.merchant-services-data.merchants'),
      t('partner-portal.merchant-services-data.view-merchants')
    )
  }, [])

  const datasource = useMemo(() => {
    return new MerchantServicesApi(companyId.id)
  }, [])

  return (
    <section>
      <HasPermission
        allPermissions={[
          'v1.partner.merchants.get',
          'v1.partner.merchants.get.services',
        ]}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <div className={`ag-theme-alpine ${classes.grid}`}>
          {/* <div className={classes.button}>
            <Button style={{ backgroundColor: '#00263A' }} variant="contained">
              New Service
            </Button>
          </div> */}
          <PaginationTable<MerchantServices>
            columnDefs={columnDefs}
            domLayout="autoHeight"
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            defaultColDef={defaultColDef}
            guidingId="merchants-details-services"
          />
        </div>
      </HasPermission>
    </section>
  )
}
