import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { ColDef } from 'ag-grid-community'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Link, Typography } from '@mui/material'

import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { api } from '@shared/api'
import { HasPermission } from '@shared/components'
import AppTheme from '@shared/design'
import { downloadFile } from '@shared/files'
import { useEnforceLogin } from '@shared/hooks'
import { Commission, EnumServiceName } from '@shared/types'
import { formatDatetime, DataSource } from '@shared/utils'

const useStyles = tss.withName('CommissionsGrid').create(({ theme }) => ({
  link: {
    color: theme.palette['balance-blue'],
  },
  notAllowed: {
    color: theme.palette['neutral-300'],
  },
}))

interface ExtendedCommission extends Commission {
  export?: ReactJSXElement
}

export default function CommissionsGrid() {
  const { t } = useTranslation()
  const { classes } = useStyles(AppTheme)
  const { user } = useEnforceLogin()

  const columnDefs: ColDef<ExtendedCommission>[] = useMemo(
    () => [
      {
        field: 'statement_name',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterOptions: ['equals'],
        },
        sortable: true,
        headerName: t('partner-portal.commissions.statement-name'),
        suppressMovable: true,
        valueGetter: (params) => {
          return params.data.statement_name || '-'
        },
        minWidth: 250,
      },
      {
        field: 'agent_id',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterOptions: ['equals'],
        },
        sortable: true,
        headerName: t('partner-portal.commissions.agent-id'),
        suppressMovable: true,
        valueGetter: (params) => {
          return params.data.agent_id || '-'
        },
      },
      {
        field: 'agent_name',
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterOptions: ['equals'],
        },
        sortable: true,
        headerName: t('partner-portal.commissions.agent-name'),
        suppressMovable: true,
        valueGetter: (params) => {
          return params.data.agent_name || '-'
        },
      },
      {
        field: 'created_ts',
        // filter: 'agDateColumnFilter',
        // floatingFilter: true,
        sortable: true,
        headerName: t('partner-portal.commissions.posted'),
        suppressMovable: true,
        valueFormatter: (params) => {
          return params.data.created_ts
            ? formatDatetime(params.data.created_ts, user.tz)
            : '-'
        },
      },
      {
        field: 'export',
        filter: true,
        headerName: t('partner-portal.commissions.export'),
        suppressMovable: true,
        minWidth: 150,
        cellRenderer: (params) => (
          <HasPermission
            permission="v1.partner.commissions.get.download"
            unauthorizedComponent={
              <Typography className={classes.notAllowed}>
                {t('partner-portal.commissions.download-file')}
              </Typography>
            }
          >
            <Link
              href="#"
              className={classes.link}
              onClick={async (e) => {
                e.preventDefault()

                const data = await api
                  .service('commissions')
                  .download(params.data.document_id)

                downloadFile(data.url, params.data.document_id)
              }}
            >
              {t('partner-portal.commissions.download-file')}
            </Link>
          </HasPermission>
        ),
      },
    ],
    []
  )

  const datasource = useMemo(() => {
    return new DataSource('commissions')
  }, [])

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  return (
    <PaginationTable<Commission>
      getRowId={(data) => data.data.document_id}
      rowModelType={'serverSide'}
      columnDefs={columnDefs}
      serverSideDatasource={datasource}
      defaultColDef={defaultColDef}
      serviceName={EnumServiceName.Commissions}
      guidingId="commissions"
    />
  )
}
