import React from 'react'
export const MerchantsIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.0002 26.8301L21.3335 26.8299"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.3732 26.8301V13.4795"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.3335 22.7952V20.2247C5.3335 19.5012 5.62092 18.8073 6.13253 18.2957C6.64415 17.7841 7.33804 17.4967 8.06156 17.4967H10.6315C11.355 17.4967 12.0489 17.7841 12.5605 18.2956L16.5344 22.2691C17.5997 23.3343 17.5999 25.0615 16.5347 26.1269L13.9648 28.6975C13.4532 29.2091 12.7593 29.4966 12.0358 29.4967C11.3122 29.4968 10.6183 29.2094 10.1066 28.6978L6.13263 24.7243C5.62095 24.2127 5.33349 23.5187 5.3335 22.7952Z"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.28609 21.4495C9.31215 21.4236 9.35433 21.4236 9.38034 21.4496C9.40635 21.4757 9.40633 21.5179 9.38031 21.5439C9.35429 21.5699 9.3121 21.5699 9.28606 21.5439C9.26002 21.5179 9.25998 21.4757 9.28597 21.4497L9.28609 21.4495"
      stroke="#D64123"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.0346 8.80028C2.64109 9.54134 2.56285 10.4097 2.81756 11.2092C3.25817 12.5961 4.55972 13.5271 6.01462 13.496C7.46953 13.4649 8.7301 12.4791 9.11102 11.0746C9.13517 10.993 9.21011 10.9371 9.29519 10.9371C9.38027 10.9371 9.45521 10.993 9.47936 11.0746C9.86834 12.5042 11.1665 13.4962 12.648 13.496C14.1295 13.4959 15.4275 12.5036 15.8161 11.0739C15.8402 10.9923 15.9152 10.9363 16.0003 10.9363C16.0854 10.9363 16.1603 10.9923 16.1844 11.0739C16.5733 12.5035 17.8714 13.4957 19.3529 13.4956C20.8345 13.4955 22.1325 12.5034 22.5213 11.0737C22.5454 10.9922 22.6204 10.9362 22.7054 10.9362C22.7905 10.9362 22.8654 10.9922 22.8896 11.0737C23.2706 12.4781 24.531 13.4637 25.9858 13.4948C27.4406 13.526 28.7421 12.5952 29.1828 11.2085C29.4377 10.4089 29.3595 9.54035 28.9659 8.79916L26.6902 4.29437C26.2366 3.39634 25.3161 2.83008 24.31 2.83008H7.69101C6.6849 2.83008 5.76444 3.39634 5.3108 4.29437L3.0346 8.80028Z"
      stroke="#D64123"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
