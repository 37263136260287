import { LicenseManager } from 'ag-grid-enterprise'
import { useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'

import AddressablePage from '@shared/addressable-page-manager/src/AddressablePage'
import AddressablePageManager from '@shared/addressable-page-manager/src/AddressablePageManager'
import { User, api } from '@shared/api/src'
import {
  ErrorHandler,
  InactivityModal,
  PasswordExpirationCheck,
} from '@shared/components'
import AppTheme from '@shared/design'
import {
  EnforceLoginProvider,
  AuthorizationProvider,
  FtpPortalHubCommunicationProvider,
  useFtpPortalHubCommunication,
  NotificationProvider,
} from '@shared/hooks'
import {
  DashboardIcon,
  MerchantsIcon,
  ApplicationIcon,
  ReportsIcon,
  SettingsIcon,
} from '@shared/icons'
import {
  checkSomePermissions,
  createUserPermissionSet,
  setupChatBotPartnerPortal,
  setupGoogleAnalytics,
  setupSentry,
} from '@shared/utils'

import { PartnerFullIcon, PartnerShortIcon } from '@/assets/icons'

import i18n from './i18n'
import { ApplicationTemplates } from './pages/application-templates/application-templates-grid/ApplicationTemplates'
import { ApplicationTemplatesTemplateInfo } from './pages/application-templates/application-templates-template-info/ApplicationTemplatesTemplateInfo'
import { PaymentAndVelocity } from './pages/application-templates/payment-and-velocity/PaymentAndVelocity'
import { PricingPlan } from './pages/application-templates/pricing-plan/PricingPlan'
import Product from './pages/application-templates/product/Product'
import ApplicationDetails from './pages/applications/application-details/ApplicationDetails'
import Applications from './pages/applications/Applications'
import Commissions from './pages/commissions/Commissions'
import Dashboard from './pages/dashboard/Dashboard'
import MerchantDetails from './pages/merchants/merchant-details/MerchantDetails'
import Merchants from './pages/merchants/Merchants'
import packageJSON from '../package.json'

LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE)

export const LOCAL_STORAGE_SESSION_TOKEN_KEY = 'token'

const PartnerPortalApp = () => {
  const { t } = useTranslation()
  const [user, setUser] = useState<User | null>(null)

  const [addressablePageManager, setAddressablePageManager] =
    useState<AddressablePageManager>()

  const userPermissions = createUserPermissionSet(user)

  const {
    setNavigatorMenu,
    setApplicationBanner,
    setPortalVersion,
    setAppShowSearchBar,
    setAppShowLocationBar,
  } = useFtpPortalHubCommunication()

  const hasSomePermissionSettings = checkSomePermissions(
    userPermissions,
    'v2.locationinfos.get',
    'v2.producttransactiondetails.get',
    'v2.userprefs.get',
    'v2.useruserprefs.get',
    'v2.quickinvoicesettings.get',
    'v2.productrecurrings.get',
    'v2.users.get'
  )

  const pages = useMemo(
    () => [
      new AddressablePage(
        t('common.dashboard'),
        <Dashboard />,
        [],
        '/partner',
        <DashboardIcon />,
        false,
        true,
        'nav-partner-dashboard'
      ),
      new AddressablePage(
        t('partner-portal.applications'),
        <></>,
        [],
        undefined,
        <ApplicationIcon />,
        false,
        true,
        'nav-partner-applications'
      )
        .addNestedPage(
          new AddressablePage(
            t('partner-portal.applications'),
            <Applications />,
            ['v1.partner.applications.get'],
            '/partner/applications',
            undefined,
            false,
            true,
            'nav-partner-applications'
          )
        )
        .addNestedPage(
          new AddressablePage(
            t('partner-portal.application-templates.templates'),
            <ApplicationTemplates />,
            ['v1.partner.applications.get'],
            '/partner/application-templates',
            undefined,
            true, // Hidden until ready for production (PPW-783)
            true,
            'nav-partner-application-templates'
          )
        ),
      new AddressablePage(
        t('partner-portal.application-details'),
        <ApplicationDetails />,
        ['v1.partner.applications.get'],
        '/partner/applications/:id',
        undefined,
        true
      ),
      new AddressablePage(
        t('partner-portal.application-templates.add-application-template'),
        <ApplicationTemplatesTemplateInfo />,
        [],
        '/partner/application-templates/new',
        undefined,
        true
      ),
      new AddressablePage(
        t('partner-portal.application-templates.payment-and-velocity'),
        <PaymentAndVelocity />,
        [],
        '/partner/application-templates/:templateId/payment-and-velocity',
        undefined,
        true
      ),
      new AddressablePage(
        t('partner-portal.application-templates.product'),
        <Product />,
        [],
        '/partner/application-templates/:templateId/product',
        undefined,
        true
      ),
      new AddressablePage(
        t('partner-portal.application-templates.pricing-plan'),
        <PricingPlan />,
        [],
        '/partner/application-templates/:templateId/pricing-plan',
        undefined,
        true
      ),
      new AddressablePage(
        t('partner-portal.merchants'),
        <Merchants />,
        ['v1.partner.merchants.get'],
        '/partner/merchants',
        <MerchantsIcon />,
        false,
        true,
        'nav-partner-merchants'
      ),
      new AddressablePage(
        t('partner-portal.merchant-details'),
        <MerchantDetails />,
        ['v1.partner.merchants.get'],
        '/partner/merchants/:id/*',
        undefined,
        true
      ),
      new AddressablePage(
        t('partner-portal.reports'),
        <></>,
        [],
        undefined,
        <ReportsIcon />,
        false,
        true,
        'nav-partner-reports'
      ).addNestedPage(
        new AddressablePage(
          t('partner-portal.commissions'),
          <Commissions />,
          ['v1.partner.commissions.get'],
          '/partner/reports/commissions',
          undefined,
          false,
          true,
          'nav-partner-reports-commissions'
        )
      ),
      // TODO: wait for product to request users management in Partner Portal. existing Users Management funcationality is Merchant Portal specific
      // new AddressablePage(
      //   t('common.settings'),
      //   <></>,
      //   [],
      //   undefined,
      //   <SettingsIcon />,
      //   false,
      //   hasSomePermissionSettings
      // ).addNestedPage(
      //   new AddressablePage(
      //     t('common.users-management'),
      //     <Outlet />,
      //     ['v2.users.get'],
      //     '/partner/user-management'
      //   )
      // ),
    ],
    [userPermissions, hasSomePermissionSettings]
  )

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const fetchedUser: any = await getUser()
        setUser(fetchedUser)
      } catch (error) {
        console.error('ERROR GETTING/SETTING USER DATA:', error.toString())
      }
    }

    fetchUser()
    setApplicationBanner({
      expanded: PartnerFullIcon,
      collapsed: PartnerShortIcon,
    })
  }, [])

  useEffect(() => {
    if (!user) return
    setAddressablePageManager(new AddressablePageManager(pages, user))
  }, [user])

  useEffect(() => {
    if (!addressablePageManager) return
    setNavigatorMenu({
      menuItems: addressablePageManager.generateAllNavigatorMenuItems(),
    })
  }, [addressablePageManager])

  useEffect(() => {
    setPortalVersion({ version: packageJSON?.version })
    setAppShowSearchBar(true)
    setAppShowLocationBar(false)

    // Load 3rd party plugins
    // GOOGLE ANALYTICS
    if (process.env.REACT_GA_TRACKING) {
      setupGoogleAnalytics()
    }
    // SENTRY
    if (process.env.SENTRY_DNS) {
      setupSentry()
    }
    // CHATBOT
    if (process.env.CHATBOT_PARTNER_ID) {
      setupChatBotPartnerPortal()
    }
  }, [])

  async function getUser() {
    const user = await api.service('users').authorize()

    setUser(user)
    return user
  }

  if (!addressablePageManager) return null

  return (
    <AuthorizationProvider user={user}>
      <NotificationProvider>
        <ThemeProvider theme={AppTheme}>
          <Routes>
            {addressablePageManager.renderAllAddressablePages()}
            <Route path="/partner/userprofile/*" element={<Outlet />} />
          </Routes>
          <InactivityModal />
          <PasswordExpirationCheck />
        </ThemeProvider>
      </NotificationProvider>
    </AuthorizationProvider>
  )
}

const PartnerPortal: React.FC = () => {
  return (
    <FtpPortalHubCommunicationProvider>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <EnforceLoginProvider redirectToLogin>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <PartnerPortalApp />
            </BrowserRouter>
          </I18nextProvider>
        </EnforceLoginProvider>
      </ErrorBoundary>
    </FtpPortalHubCommunicationProvider>
  )
}

export default PartnerPortal
