import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useState } from 'react'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { AppBar, Box, Grid, Typography } from '@mui/material'

import { api } from '@shared/api/src'
import {
  PageLayoutContainer,
  Input,
  ButtonBar,
  ButtonBarEnd,
  Button,
  SelectComponent,
  SelectOption,
  RadioButtons,
  RadioButtonsArray,
} from '@shared/components'
import { useEnforceLogin } from '@shared/hooks'
import { useFtpPortalHubCommunication } from '@shared/hooks/useFtpPortalHubCommunication'
import { clearBlankFields } from '@shared/utils'
import { removeStashedItemGroup } from '@shared/utils/local-storage-stash/localStorageStash'

import {
  getTemplateInfoFieldLocalStorageKey,
  TEMPLATE_INFO_FIELD_LOCAL_STORAGE_KEY_PREFIX,
} from '@/utils/application-templates/getTemplateInfoFieldLocalStorageKey'

import { ApplicationTemplateStepper } from '../components/application-template-stepper/ApplicationTemplateStepper'

// TODO: Move Enums / Mock Data / Utils to their own files.

// Constants

export const mockTemplateId = 'mock-template-id'

// Enums
export enum ApplicationTypeCode {
  CC = 1,
  ACH = 2,
  'ACH-CC' = 3,
}

// TODO: To be updated, values are temporarily set to 1
enum CCPlatformCode {
  DEFAULT = 1,
}

enum ACHPlatformCode {
  FORTIS = 1,
}

// TODO: To be updated, values are temporarily set to 1
enum ACHBankCode {
  KEY_BANK = 1,
  FIFTH_THIRD_BANK = 1,
}

const businessCategories: SelectOption<string>[] = [
  { value: 'business-category-1', label: 'Business Category 1' },
  { value: 'business-category-2', label: 'Business Category 2' },
]

const businessTypes: SelectOption<string>[] = [
  { value: 'business-type-1', label: 'Business Type 1' },
  { value: 'business-type-2', label: 'Business Type 2' },
]

const ccProcessorPlatforms: SelectOption<string>[] = [
  { value: CCPlatformCode.DEFAULT, label: 'CC Processor Platform 1' },
]

const achProcessorPlatforms: SelectOption<string>[] = [
  {
    value: ACHPlatformCode.FORTIS,
    label: 'Fortis',
  },
]

const achProcessingBanks: SelectOption<string>[] = [
  { value: ACHBankCode.KEY_BANK, label: 'KeyBank' },
  { value: ACHBankCode.FIFTH_THIRD_BANK, label: 'Fifth Third Bank' },
]

// Utils
const showCCFields = (applicationTypeCode: ApplicationTypeCode) =>
  applicationTypeCode === ApplicationTypeCode.CC ||
  applicationTypeCode === ApplicationTypeCode['ACH-CC']

const showACHFields = (applicationTypeCode: ApplicationTypeCode) =>
  applicationTypeCode === ApplicationTypeCode.ACH ||
  applicationTypeCode === ApplicationTypeCode['ACH-CC']

const buildSchema = (t: TFunction) =>
  yup.object().shape({
    template_name: yup
      .string()
      .required(
        t('partner-portal.application-templates.you-must-enter-a-template-name')
      ),
    office_name: yup
      .string()
      .required(
        t('partner-portal.application-templates.you-must-enter-an-office-name')
      ),
    agent_name: yup.string(),

    application_type_code: yup
      .number()
      .oneOf([
        ApplicationTypeCode.CC,
        ApplicationTypeCode.ACH,
        ApplicationTypeCode['ACH-CC'],
      ])
      .required(
        t(
          'partner-portal.application-templates.you-must-select-an-application-type'
        )
      ),

    platform_code: yup
      .number()
      .oneOf([CCPlatformCode.DEFAULT])
      .when(['application_type_code'], {
        is: showCCFields,
        then: (schema) =>
          schema.required(
            t(
              'partner-portal.application-templates.you-must-select-a-cc-processor-platform'
            )
          ),
      }),

    ach_platform_code: yup
      .number()
      .oneOf([ACHPlatformCode.FORTIS])
      .when(['application_type_code'], {
        is: showACHFields,
        then: (schema) => schema.required(),
      }),

    ach_bank_code: yup
      .number()
      .oneOf([ACHBankCode.KEY_BANK, ACHBankCode.FIFTH_THIRD_BANK]),

    business_category: yup.string(),
    business_type: yup.string(),
    business_description: yup.string(),
  })

const useStyles = tss
  .withName('ApplicationTemplatesTemplateInfo')
  .create(({ theme }) => ({
    title: {
      color: theme.palette['neutral-700'],
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '28px',
    },
    appBar: {
      position: 'fixed',
      top: 'auto',
      bottom: 0,
      boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
    },
  }))

export const ApplicationTemplatesTemplateInfo: FC = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { user } = useEnforceLogin()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const navigate = useNavigate()

  const [officeNames, setOfficeNames] = useState<SelectOption<string>[]>([])
  const [agentNames, setAgentNames] = useState<SelectOption<string>[]>([])

  const schema = buildSchema(t)
  type Data = yup.InferType<typeof schema>

  const methods = useForm<Data>({
    defaultValues: {
      template_name: '',
      office_name: '',
      agent_name: '',
      application_type_code: undefined,
      platform_code: undefined,
      ach_platform_code: undefined,
      ach_bank_code: undefined,
      business_category: '',
      business_type: '',
      business_description: '',
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  const {
    control,
    setValue,
    setError,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods

  const applicationTypeCode = watch('application_type_code')
  const selectedOfficeName = watch('office_name')

  const applicationTypeOptions: RadioButtonsArray = [
    {
      title: 'ACH',
      color: 'secondary',
      onClick: () => setValue('application_type_code', ApplicationTypeCode.ACH),
      defaultSelected: applicationTypeCode === ApplicationTypeCode.ACH,
      testId: 'application-type-ach-button',
    },
    {
      title: 'CC',
      color: 'secondary',
      onClick: () => setValue('application_type_code', ApplicationTypeCode.CC),
      defaultSelected: applicationTypeCode === ApplicationTypeCode.CC,
      testId: 'application-type-cc-button',
    },
    {
      title: 'ACH & CC',
      color: 'secondary',
      onClick: () =>
        setValue('application_type_code', ApplicationTypeCode['ACH-CC']),
      defaultSelected: applicationTypeCode === ApplicationTypeCode['ACH-CC'],
      testId: 'application-type-ach-cc-button',
    },
  ]

  const removeStashedFields = () =>
    removeStashedItemGroup(TEMPLATE_INFO_FIELD_LOCAL_STORAGE_KEY_PREFIX)

  const getOfficeNames = () => {
    let arrayOfficeNames = []

    user?.locations.forEach((location) => {
      arrayOfficeNames.push({
        value: location.id,
        label: location.name,
      })

      if (location.location_type === 'agent') {
        arrayOfficeNames.push({
          value: location.parent_id,
          label: location.parent_name,
        })
      }
    })

    // Sort the array alphabetically by label
    arrayOfficeNames.sort((a, b) => a.label.localeCompare(b.label))

    setOfficeNames(arrayOfficeNames)
  }

  const onSubmit: SubmitHandler<Data> = (data) => {
    const payload = clearBlankFields(data)

    localStorage.setItem(
      getTemplateInfoFieldLocalStorageKey('template_id'),
      mockTemplateId
    )

    Object.entries(payload).forEach(([field, value]) => {
      const key = getTemplateInfoFieldLocalStorageKey(field)
      localStorage.setItem(key, String(value))

      // eslint-disable-next-line
      console.log(
        `Field: ${field}\n` +
          `Local Storage Key: ${key}\n` +
          `Value: ${localStorage.getItem(key)}`
      )
    })

    navigate(
      `/partner/application-templates/${mockTemplateId}/payment-and-velocity`
    )
  }

  useEffect(() => {
    setAppBarTitle(t('partner-portal.application-templates.create-template'))
    removeStashedFields()
    getOfficeNames()
  }, [])

  useEffect(() => {
    getOfficeNames()
  }, [user])

  useEffect(() => {
    if (errors.application_type_code) {
      setError('application_type_code', null)
    }

    if (applicationTypeCode === ApplicationTypeCode.CC) {
      setValue('ach_platform_code', undefined)
      setValue('ach_bank_code', undefined)
    }

    if (applicationTypeCode === ApplicationTypeCode.ACH) {
      setValue('platform_code', undefined)
    }

    if (showACHFields(applicationTypeCode)) {
      setValue('ach_platform_code', ACHPlatformCode.FORTIS)
    }
  }, [applicationTypeCode])

  useEffect(() => {
    const fetchAgents = async () => {
      if (!selectedOfficeName) {
        return
      }

      const agents = await api
        .service('agents')
        .getOfficeAgents(selectedOfficeName)

      let arrayAgentNames = []

      agents.forEach((agent) => {
        arrayAgentNames.push({
          value: agent.agent_id,
          label: agent.name,
        })
      })

      setValue('agent_name', '')
      setAgentNames(arrayAgentNames)
    }

    fetchAgents()
  }, [selectedOfficeName])

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={2} sx={{ padding: '24px' }}>
            <ApplicationTemplateStepper activeStep={0} />
          </Grid>

          <Grid item xs={12} md={9}>
            <PageLayoutContainer>
              <Box sx={{ padding: '24px' }}>
                <Typography className={classes.title}>
                  {t('partner-portal.application-templates.template-info')}
                </Typography>

                <Box sx={{ marginTop: '32px' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="template_name"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            testId="template-name-input"
                            label={t(
                              'partner-portal.application-templates.template-name'
                            )}
                            placeholder={t(
                              'partner-portal.application-templates.template-name-placeholder'
                            )}
                            required
                            error={Boolean(errors.template_name)}
                            helperText={errors.template_name?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <RadioButtons
                        testId="application-type-radio-buttons"
                        label={t(
                          'partner-portal.application-templates.application-type'
                        )}
                        buttons={applicationTypeOptions}
                        required
                        error={Boolean(errors.application_type_code)}
                        helperText={errors.application_type_code?.message}
                      />
                    </Grid>

                    {showCCFields(applicationTypeCode) && (
                      <Grid item xs={12} md={6}>
                        <Controller
                          name="platform_code"
                          control={control}
                          render={({ field }) => (
                            <SelectComponent
                              {...field}
                              testId="cc-processor-platform-input"
                              label={t(
                                'partner-portal.application-templates.cc-processor-platform'
                              )}
                              placeholder={t(
                                'partner-portal.application-templates.cc-processor-platform-placeholder'
                              )}
                              options={ccProcessorPlatforms}
                              style={{
                                height: '45px',
                              }}
                              required
                              error={Boolean(errors.platform_code)}
                              helperText={errors.platform_code?.message}
                            />
                          )}
                        />
                      </Grid>
                    )}

                    {showACHFields(applicationTypeCode) && (
                      <>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name="ach_platform_code"
                            control={control}
                            render={({ field }) => (
                              <SelectComponent
                                {...field}
                                testId="ach-processor-platform-input"
                                label={t(
                                  'partner-portal.application-templates.ach-processor-platform'
                                )}
                                placeholder={t(
                                  'partner-portal.application-templates.ach-processor-platform-placeholder'
                                )}
                                options={achProcessorPlatforms}
                                style={{
                                  height: '45px',
                                }}
                                required
                                disabled
                                error={Boolean(errors.ach_platform_code)}
                                helperText={errors.ach_platform_code?.message}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Controller
                            name="ach_bank_code"
                            control={control}
                            render={({ field }) => (
                              <SelectComponent
                                {...field}
                                testId="ach-processing-bank-input"
                                label={t(
                                  'partner-portal.application-templates.ach-processing-bank'
                                )}
                                placeholder={t(
                                  'partner-portal.application-templates.ach-processing-bank-placeholder'
                                )}
                                options={achProcessingBanks}
                                style={{
                                  height: '45px',
                                }}
                                error={Boolean(errors.ach_bank_code)}
                                helperText={errors.ach_bank_code?.message}
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} md={6}>
                      <Controller
                        name="office_name"
                        control={control}
                        render={({ field }) => (
                          <SelectComponent
                            {...field}
                            testId="office-name-input"
                            label={t(
                              'partner-portal.application-templates.office-name'
                            )}
                            placeholder={t(
                              'partner-portal.application-templates.office-name-placeholder'
                            )}
                            options={officeNames}
                            required
                            style={{
                              height: '45px',
                            }}
                            error={Boolean(errors.office_name)}
                            helperText={errors.office_name?.message}
                          />
                        )}
                      />
                    </Grid>

                    {selectedOfficeName && (
                      <>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name="agent_name"
                            control={control}
                            render={({ field }) => (
                              <SelectComponent
                                {...field}
                                testId="agent-name-input"
                                label={t(
                                  'partner-portal.application-templates.agent-name'
                                )}
                                placeholder={t(
                                  'partner-portal.application-templates.agent-name-placeholder'
                                )}
                                options={agentNames}
                                style={{
                                  height: '45px',
                                }}
                                infoText={t(
                                  'partner-portal.application-templates.agent-name-tooltip'
                                )}
                                error={Boolean(errors.agent_name)}
                                helperText={errors.agent_name?.message}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Controller
                            name="business_category"
                            control={control}
                            render={({ field }) => (
                              <SelectComponent
                                {...field}
                                testId="business-category-select-input"
                                label={t(
                                  'partner-portal.application-templates.business-category'
                                )}
                                placeholder={t(
                                  'partner-portal.application-templates.business-category-placeholder'
                                )}
                                options={businessCategories}
                                style={{
                                  height: '45px',
                                }}
                                error={Boolean(errors.business_category)}
                                helperText={errors.business_category?.message}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Controller
                            name="business_type"
                            control={control}
                            render={({ field }) => (
                              <SelectComponent
                                {...field}
                                testId="business-type-select-input"
                                label={t(
                                  'partner-portal.application-templates.business-type'
                                )}
                                placeholder={t(
                                  'partner-portal.application-templates.business-type-placeholder'
                                )}
                                options={businessTypes}
                                style={{
                                  height: '45px',
                                }}
                                error={Boolean(errors.business_type)}
                                helperText={errors.business_type?.message}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Controller
                            name="business_description"
                            control={control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                testId="products-or-services-description-input"
                                isTextArea
                                inputProps={{
                                  style: { height: '132px' },
                                  maxLength: 200,
                                }}
                                label={t(
                                  'partner-portal.application-templates.products-or-services-description'
                                )}
                                placeholder={t(
                                  'partner-portal.application-templates.products-or-services-description-placeholder'
                                )}
                                error={Boolean(errors.business_description)}
                                helperText={
                                  errors.business_description?.message
                                }
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Box>
            </PageLayoutContainer>
          </Grid>
        </Grid>

        <AppBar className={classes.appBar}>
          <ButtonBar style={{ marginBottom: '0 !important' }}>
            <ButtonBarEnd>
              <Button
                label={t('partner-portal.application-templates.quit')}
                color="secondary"
                style={{ marginRight: '16px' }}
                onClick={() => {
                  removeStashedFields()
                  navigate('/partner/application-templates')
                }}
              />

              <Button
                testId="new-template-button"
                type="submit"
                label={t(
                  'partner-portal.application-templates.save-and-continue'
                )}
              />
            </ButtonBarEnd>
          </ButtonBar>
        </AppBar>
      </form>
    </FormProvider>
  )
}
