export const mapAppStatus = (status_id: number | undefined) => {
  switch (status_id) {
    case 1:
      return 'New by Agent'
    case 3:
      return 'Ready to Send'
    case 5:
      return 'Sent to Merchant'
    case 10:
      return 'Merchant Started'
    case 15:
      return 'Merchant Completed'
    default:
      return 'Unknown'
  }
}

export const mapAppOwnershipType = (ownership_type: string | undefined) => {
  switch (ownership_type?.toUpperCase()) {
    case 'C':
      return 'Public Corporation'
    case 'GOV':
      return 'Government'
    case 'LLC':
      return 'Limited Liability Company'
    case 'LLP':
      return 'Limited Liability Partnership'
    case 'NP':
      return 'Non-Profit'
    case 'P':
      return 'Partnership'
    case 'PO':
      return 'Political Organization'
    case 'S':
      return 'Private Corporation'
    case 'SP':
      return 'Sole Proprietor'
    case 'TE':
      return 'Other Tax Exempt'
    default:
      return 'Unknown'
  }
}
