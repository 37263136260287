import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Merchant } from '@shared/api/src/schemas/types'
import { PageLayoutContainer, NoData } from '@shared/components'

import { GeneralData } from './GeneralData'
import { getMerchantInfo } from '../../api/merchantDetailsApi'

export function GeneralInfo(props: { id?: string }) {
  const { t } = useTranslation()
  const [merchantData, setMerchantData] = useState({} as Merchant)
  const [isLoading, setIsLoading] = useState(false)

  const getMerchantDetails = async () => {
    try {
      const response = await getMerchantInfo(props.id)
      setMerchantData(response)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('error: ', error)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const fetchData = async () => {
      if (props.id) {
        await getMerchantDetails()
      }
    }

    fetchData()
  }, [])

  return (
    <PageLayoutContainer>
      {merchantData && Object.keys(merchantData).length > 0 ? (
        <GeneralData merchant={merchantData} />
      ) : isLoading ? (
        <h1>{t('common.loading')}</h1>
      ) : (
        <NoData
          title={t('partner-portal.merchant-general-info.merchant-not-found')}
          label={t(
            'partner-portal.merchant-general-info.sorry-we-are-having-trouble-finding-the-id-you-are-looking-for'
          )}
        />
      )}
    </PageLayoutContainer>
  )
}
