import { IServerSideGetRowsParams } from 'ag-grid-community'

export interface MerchantEquipment {
  equipment_id: string
  status_code: 'A' | 'I'
  terminal_id: string
  merchant_id: string
  model: string
  serial_number: '1' | '2' | '3' | '4'
  platform: string
  program: string
}

export interface MerchantEquipmentResponse {
  list: MerchantEquipment[]
}

/**
 * DataSource to get data from the Merchant Equipment api on server side model
 */
export function MerchantEquipmentDataSource() {
  this.getRows = (params: IServerSideGetRowsParams) => {
    params.api.hideOverlay()

    fetch('http://localhost:3011/merchant-equipment')
      .then((res) => res.json() as unknown as MerchantEquipmentResponse)
      .then((data) => {
        params.success({
          rowData: data.list,
          rowCount: data.list.length,
        })

        if (!data.list.length) params.api.showNoRowsOverlay()
      })
      .catch(params.fail)
  }
}
