import { Serie } from '@nivo/line'
import { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, CircularProgress, Grid, Paper } from '@mui/material'

import { api } from '@shared/api'
import { MonthlyResiduals } from '@shared/api/src/schemas/types'
import { BaseChart, LineChart } from '@shared/components'
import AppTheme from '@shared/design'
import { ChartEntity, CustomPoint } from '@shared/types'
import { currency } from '@shared/utils'

const useStyles = tss.withName('MonthlyResiduals').create(() => ({
  loadingContainer: {
    display: 'grid',
    height: '100%',
    placeContent: 'center',
  },
  tooltip: {
    padding: '0.5rem',
    backgroundColor: '#4B5563',
    color: '#F3F4F6',
    fontSize: '0.75rem',
    fontFamily: 'Inter',
  },
  p: {
    margin: 0,
    padding: 0,
    whiteSpace: 'nowrap',
  },
}))

enum ChartEntityId {
  PRIOR_YEAR = 'prioryear',
  CURRENT_YEAR = 'currentyear',
}

const CURRENT_YEAR = new Date().getFullYear()

const entities: ChartEntity[] = [
  { id: ChartEntityId.PRIOR_YEAR, name: (CURRENT_YEAR - 1).toString() },
  { id: ChartEntityId.CURRENT_YEAR, name: CURRENT_YEAR.toString() },
]

const colorsByChartEntity: Record<string, string> = {
  [(CURRENT_YEAR - 1).toString()]: '#82BE9F',
  [CURRENT_YEAR.toString()]: '#4197CB',
}

const getLineChartData = (residualYears: MonthlyResiduals[], t: TFunction) => {
  let totalCurrentYear = 0
  let totalPriorYear = 0
  let totalPreviousMonth = 0
  let highestMonthlyValue = 0

  if (!residualYears.length) return null

  const previousMonth = DateTime.now().minus({ months: 1 }).month
  const values = {
    data: residualYears.map((year) => {
      const chartEntity = entities.find((entity) => entity.id === year.id)
      chartEntity.name = DateTime.fromMillis(year.range[0] * 1000)
        .plus({ days: 1 })
        .toFormat('yyyy')
      return {
        id: chartEntity.name,
        data: year.values.map((value, index) => {
          if (index === previousMonth) {
            totalPreviousMonth += value[0]
          }

          if (chartEntity.id === ChartEntityId.CURRENT_YEAR) {
            totalCurrentYear += value[0]
          }

          if (chartEntity.id === ChartEntityId.PRIOR_YEAR) {
            totalPriorYear += value[0]
          }

          if (value[0] > highestMonthlyValue) highestMonthlyValue = value[0]

          return {
            id: chartEntity.name,
            x: t(
              `partner-portal.charts.${DateTime.fromMillis(
                year.range[index] * 1000
              )
                .plus({ days: 1 })
                .toFormat('MMM')
                .toLowerCase()}`
            ),
            y: value[0],
            date: DateTime.fromMillis(year.range[index] * 1000).plus({
              days: 1,
            }),
          }
        }),
      }
    }),
    totalCurrentYear,
    totalPriorYear,
    totalPreviousMonth,
    highestMonthlyValue,
  }
  return values
}

export default function MonthlyResidualsChart() {
  const { t } = useTranslation()
  const { classes } = useStyles(AppTheme)

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const [lineChartData, setLineChartData] = useState<{
    totalCurrentYear: number
    totalPreviousMonth: number
    totalPriorYear: number
    highestMonthlyValue: number
    data: Serie[]
  } | null>()

  const updateMonthlyResiduals = (monthlyResiduals: MonthlyResiduals[]) => {
    setLineChartData(getLineChartData(monthlyResiduals, t))
  }

  useEffect(() => {
    setIsLoading(true)

    api
      .service('dashboard-monthly-residuals')
      .find()
      .then((monthlyResiduals) => {
        updateMonthlyResiduals(monthlyResiduals)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        setIsError(true)
        console.error(error)
      })
  }, [])

  const getColorByChartEntity = (chartEntity: { id: string }): string =>
    colorsByChartEntity[chartEntity.id]

  const transactionsInfo = (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={4}>
        <div>
          <p className={classes.p}>{entities[0].name}</p>
          <p className={classes.p} style={{ fontWeight: 'bold' }}>
            {currency(lineChartData ? lineChartData['totalPriorYear'] : 0)}
          </p>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <p className={classes.p} data-testid="monthly-residuals-ytd">
            {entities[1].name} {t('partner-portal.charts.ytd')}
          </p>
          <p className={classes.p} style={{ fontWeight: 'bold' }}>
            {currency(lineChartData ? lineChartData['totalCurrentYear'] : 0)}
          </p>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <p className={classes.p}>
            {t('partner-portal.monthly-residuals.previous-month')}
          </p>
          <p className={classes.p} style={{ fontWeight: 'bold' }}>
            {currency(lineChartData ? lineChartData['totalPreviousMonth'] : 0)}
          </p>
        </div>
      </Grid>
    </Grid>
  )

  return (
    <BaseChart
      title={t('partner-portal.monthly-residuals')}
      transactionsInfo={transactionsInfo}
      tooltip={t('partner-portal.monthly-residuals')}
    >
      {!!lineChartData && !isLoading ? (
        <LineChart
          data={lineChartData?.data ?? []}
          colors={getColorByChartEntity}
          margin={{
            top: 50,
            bottom: 50,
            left: lineChartData?.highestMonthlyValue
              ? lineChartData?.highestMonthlyValue.toString().length * 9.5
              : 50,
            right: 15,
          }}
          tooltip={({ point }) => {
            const { data } = point as CustomPoint
            return (
              <Paper elevation={3} className={classes.tooltip}>
                <Grid container direction={'column'}>
                  <Grid item>
                    <span>
                      {DateTime.fromJSDate(new Date(data.date)).toFormat(
                        'MMMM yyyy'
                      )}
                    </span>
                  </Grid>
                  <Grid item>
                    <span>{currency(Number(data.y))}</span>
                  </Grid>
                </Grid>
              </Paper>
            )
          }}
        />
      ) : (
        <Box className={classes.loadingContainer}>
          {isLoading ? (
            <CircularProgress data-testid="loading-icon" size={45} />
          ) : (
            <p className={classes.p}>
              {isError
                ? t('common.validations.loading-data')
                : t('common.validations.not-available-data')}
            </p>
          )}
        </Box>
      )}
    </BaseChart>
  )
}
