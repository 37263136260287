import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LOCAL_STORAGE_PASSWORD_EXP_TS_KEY } from '@shared/hooks'
import { formatDate } from '@shared/utils'

import Notification from '../notification/Notification'

const PasswordExpirationCheck = () => {
  const { t } = useTranslation()
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [expirationDate, setExpirationDate] = useState<string>(undefined)

  useEffect(() => {
    const stringTs = localStorage.getItem(LOCAL_STORAGE_PASSWORD_EXP_TS_KEY)

    if (!stringTs) {
      return
    }

    const timestamp = parseInt(stringTs)

    const daysUntilExpiration = parseInt(
      DateTime.fromSeconds(timestamp).diffNow('days').days.toFixed(0)
    )

    const formattedDate = formatDate(timestamp)

    if (formattedDate !== '-' && daysUntilExpiration <= 14) {
      setExpirationDate(formattedDate)
      setShowNotification(true)
    }

    localStorage.removeItem(LOCAL_STORAGE_PASSWORD_EXP_TS_KEY)
  }, [])

  return (
    <Notification
      show={showNotification}
      label={t('common.password-will-expire-on', {
        date: expirationDate,
        interpolation: { escapeValue: false },
      })}
      type="info"
      duration={15000}
      onClose={() => setShowNotification(false)}
    />
  )
}

export default PasswordExpirationCheck
