import { yupResolver } from '@hookform/resolvers/yup'
import { TFunction } from 'i18next'
import { ChangeEvent, FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { Close } from '@mui/icons-material'
import { Box, SelectChangeEvent, Typography } from '@mui/material'

import {
  Button,
  Input,
  RadioButtons,
  RadioButtonsArray,
  SelectComponent as Select,
  SelectOption,
} from '@shared/components'

//
export enum ProductType {
  GATEWAY = 'gateway',
  A = 'product_type_a',
  B = 'product_type_b',
  C = 'product_type_c',
  D = 'product_type_d',
}

export enum ProductProvider {
  FORTIS = 'fortis',
  MERCHANT = 'merchant',
  SALES_PARTNER = 'sales_partner',
}

export enum ProductOwnershipType {
  SALE = 'sale',
  LOANER = 'loaner',
}

export enum ProductModel {
  VAR_SHEET_ONLY = 'var_sheet_only',
  SWIPE_SIMPLE = 'swipe_simple',
  A = 'product_model_a',
  B = 'product_model_b',
  C = 'product_model_c',
  D = 'product_model_d',
}

export enum ProductFrontEndPlatform {
  OMAHA = 'omaha',
  NASHVILLE = 'nashville',
  BUYPASS = 'buypass',
  NORTH = 'north',
  TSYS = 'tsys',
}

export enum ProductBackEndPlatform {
  FORTIS_NORTH = 'fortis_north',
  FORTIS_OMAHA = 'fortis_omaha',
  FIS_PAYFAC_CORE = 'fis_payfac_core',
  PAYSAFE_OMAHA = 'paysafe_omaha',
  PAYSAFE_NORTH = 'paysafe_north',
  PAYMENT_CLOUD = 'payment_cloud',
}

export enum ProductSetupType {
  PAYMENTS = 'payments',
  TERMINAL = 'terminal',
  VIRTUAL_TERMINAL_APP_ONLY = 'virtual-terminal-app-only',
}
//

//
const productTypes: SelectOption<ProductType>[] = [
  { label: 'Gateway', value: ProductType.GATEWAY },
  { label: 'Product Type A', value: ProductType.A },
  { label: 'Product Type B', value: ProductType.B },
  { label: 'Product Type C', value: ProductType.C },
  { label: 'Product Type D', value: ProductType.D },
]

const productModels: SelectOption<ProductModel>[] = [
  { label: 'VAR Sheet Only', value: ProductModel.VAR_SHEET_ONLY },
  { label: 'Swipe Simple', value: ProductModel.SWIPE_SIMPLE },
  { label: 'Model A', value: ProductModel.A },
  { label: 'Model B', value: ProductModel.B },
  { label: 'Model C', value: ProductModel.C },
  { label: 'Model D', value: ProductModel.D },
]

const productFrontEndPlatforms: SelectOption<ProductFrontEndPlatform>[] = [
  { label: 'Omaha', value: ProductFrontEndPlatform.OMAHA },
  { label: 'Nashville', value: ProductFrontEndPlatform.NASHVILLE },
  { label: 'BuyPass', value: ProductFrontEndPlatform.BUYPASS },
  { label: 'North', value: ProductFrontEndPlatform.NORTH },
  { label: 'TSYS', value: ProductFrontEndPlatform.TSYS },
]

const productBackEndPlatforms: SelectOption<ProductBackEndPlatform>[] = [
  { label: 'Fortis North', value: ProductBackEndPlatform.FORTIS_NORTH },
  { label: 'Fortis Omaha', value: ProductBackEndPlatform.FORTIS_OMAHA },
  { label: 'FIS PayFac Core', value: ProductBackEndPlatform.FIS_PAYFAC_CORE },
  { label: 'PaySafe Omaha', value: ProductBackEndPlatform.PAYSAFE_OMAHA },
  { label: 'PaySafe North', value: ProductBackEndPlatform.PAYSAFE_NORTH },
  { label: 'Payment Cloud', value: ProductBackEndPlatform.PAYMENT_CLOUD },
]
//

const canDisplayField: Record<
  keyof AddProductData,
  (formData: AddProductData) => boolean
> = {
  product_type: () => true,

  model: ({ product_type }) => Boolean(product_type),

  frontend_platform: ({ model }) => Boolean(model),

  backend_platform: ({ frontend_platform }) => Boolean(frontend_platform),

  pos_system_name: ({ product_type, model }) =>
    product_type === ProductType.GATEWAY &&
    model === ProductModel.VAR_SHEET_ONLY,

  software_name: ({ product_type, model }) =>
    product_type === ProductType.GATEWAY &&
    model === ProductModel.VAR_SHEET_ONLY,

  equipment_name: ({ product_type, model }) =>
    product_type === ProductType.GATEWAY &&
    model === ProductModel.VAR_SHEET_ONLY,

  product_provider: ({ model }) => model !== ProductModel.VAR_SHEET_ONLY,

  ownership_type: function (formData) {
    return (
      canDisplayField.product_provider(formData) &&
      Boolean(formData.product_provider) &&
      formData.product_provider !== ProductProvider.MERCHANT
    )
  },

  setup_type: ({ product_type, model }) =>
    product_type === ProductType.GATEWAY && model === ProductModel.SWIPE_SIMPLE,
}

const buildSchema = (t: TFunction) =>
  yup.object().shape({
    product_type: yup
      .string()
      .oneOf([
        ProductType.GATEWAY,
        ProductType.A,
        ProductType.B,
        ProductType.C,
        ProductType.D,
      ])
      .required(
        t('partner-portal.application-templates.you-must-select-a-product-type')
      ),

    model: yup
      .string()
      .oneOf([
        ProductModel.VAR_SHEET_ONLY,
        ProductModel.SWIPE_SIMPLE,
        ProductModel.A,
        ProductModel.B,
        ProductModel.C,
        ProductModel.D,
      ])
      .when('product_type', {
        is: (product_type: ProductType) =>
          canDisplayField.model({ product_type }),
        then: (schema) =>
          schema.required(
            t('partner-portal.application-templates.you-must-select-a-model')
          ),
      }),

    frontend_platform: yup
      .string()
      .oneOf([
        ProductFrontEndPlatform.OMAHA,
        ProductFrontEndPlatform.NASHVILLE,
        ProductFrontEndPlatform.BUYPASS,
        ProductFrontEndPlatform.NORTH,
        ProductFrontEndPlatform.TSYS,
      ])
      .when('model', {
        is: (model: ProductModel) =>
          canDisplayField.frontend_platform({ model }),
        then: (schema) =>
          schema.required(
            t(
              'partner-portal.application-templates.you-must-select-a-front-end-platform'
            )
          ),
      }),

    backend_platform: yup
      .string()
      .oneOf([
        ProductBackEndPlatform.FORTIS_NORTH,
        ProductBackEndPlatform.FORTIS_OMAHA,
        ProductBackEndPlatform.FIS_PAYFAC_CORE,
        ProductBackEndPlatform.PAYSAFE_OMAHA,
        ProductBackEndPlatform.PAYSAFE_NORTH,
        ProductBackEndPlatform.PAYMENT_CLOUD,
      ])
      .when('frontend_platform', {
        is: (frontend_platform: ProductFrontEndPlatform) =>
          canDisplayField.backend_platform({ frontend_platform }),
        then: (schema) =>
          schema.required(
            t(
              'partner-portal.application-templates.you-must-select-a-back-end-platform'
            )
          ),
      }),

    pos_system_name: yup.string().when(['product_type', 'model'], {
      is: (product_type: ProductType, model: ProductModel) =>
        canDisplayField.pos_system_name({ product_type, model }),
      then: (schema) =>
        schema.required(
          t(
            'partner-portal.application-templates.you-must-enter-a-pos-system-name'
          )
        ),
    }),

    software_name: yup.string().when(['product_type', 'model'], {
      is: (product_type: ProductType, model: ProductModel) =>
        canDisplayField.software_name({ product_type, model }),
      then: (schema) =>
        schema.required(
          t(
            'partner-portal.application-templates.you-must-enter-a-software-name'
          )
        ),
    }),

    equipment_name: yup.string().when(['product_type', 'model'], {
      is: (product_type: ProductType, model: ProductModel) =>
        canDisplayField.equipment_name({ product_type, model }),
      then: (schema) =>
        schema.required(
          t(
            'partner-portal.application-templates.you-must-enter-an-equipment-name'
          )
        ),
    }),

    product_provider: yup
      .string()
      .oneOf([
        ProductProvider.FORTIS,
        ProductProvider.MERCHANT,
        ProductProvider.SALES_PARTNER,
      ])
      .when('model', {
        is: (model: ProductModel) =>
          canDisplayField.product_provider({ model }),
        then: (schema) =>
          schema.required(
            t(
              'partner-portal.application-templates.you-must-select-a-product-provider'
            )
          ),
      }),

    ownership_type: yup
      .string()
      .oneOf([ProductOwnershipType.SALE, ProductOwnershipType.LOANER])
      .when(['model', 'product_provider'], {
        is: (model: ProductModel, product_provider: ProductProvider) =>
          canDisplayField.ownership_type({ model, product_provider }),
        then: (schema) =>
          schema.required(
            t(
              'partner-portal.application-templates.you-must-select-an-ownership-type'
            )
          ),
      }),

    setup_type: yup
      .string()
      .oneOf([
        ProductSetupType.PAYMENTS,
        ProductSetupType.TERMINAL,
        ProductSetupType.VIRTUAL_TERMINAL_APP_ONLY,
      ])
      .when(['product_type', 'model'], {
        is: (product_type: ProductType, model: ProductModel) =>
          canDisplayField.setup_type({ product_type, model }),
        then: (schema) =>
          schema.required(
            t(
              'partner-portal.application-templates.you-must-select-a-setup-type'
            )
          ),
      }),
  })

export type AddProductData = yup.InferType<ReturnType<typeof buildSchema>>

const useStyles = tss.withName('AddProductForm').create(() => ({
  container: {
    position: 'relative',
    height: '100%',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px',
    marginBottom: '24px',
    borderBottom: `2px solid #E5E7EB`,
  },
  footerContainer: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1.5em',
    borderTop: `2px solid #E5E7EB`,
  },
  form: {
    display: 'grid',
    gap: '24px 0',
    padding: '0 24px',
  },
  closeIcon: {
    fontSize: '28px',
    fill: '#9CA3AF',
    ':hover': {
      cursor: 'pointer',
    },
  },
}))

export interface AddProductFormProps {
  onClose?: () => void
}

export const AddProductForm: FC<AddProductFormProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm<AddProductData>({
    resolver: yupResolver(buildSchema(t)),
    mode: 'onSubmit',
  })

  const formData = watch()

  const {
    product_provider: selectedProductProvider,
    ownership_type: selectedProductOwnershipType,
    setup_type: selectedProductSetupType,
  } = formData

  const productProviderButtons: RadioButtonsArray = [
    {
      testId: 'product-provider-fortis-button',
      title: t('partner-portal.application-templates.fortis'),
      color: 'secondary',
      defaultSelected: selectedProductProvider === ProductProvider.FORTIS,
      onClick: () => onChangeField('product_provider', ProductProvider.FORTIS),
    },
    {
      testId: 'product-provider-merchant-button',
      title: t('partner-portal.application-templates.merchant'),
      color: 'secondary',
      defaultSelected: selectedProductProvider === ProductProvider.MERCHANT,
      onClick: () =>
        onChangeField('product_provider', ProductProvider.MERCHANT),
    },
    {
      testId: 'product-provider-sales-partner-button',
      title: t('partner-portal.application-templates.sales-partner'),
      color: 'secondary',
      defaultSelected:
        selectedProductProvider === ProductProvider.SALES_PARTNER,
      onClick: () =>
        onChangeField('product_provider', ProductProvider.SALES_PARTNER),
    },
  ]

  const productOwnershipTypeButtons: RadioButtonsArray = [
    {
      testId: 'ownership-type-sale-button',
      title: t('partner-portal.application-templates.sale'),
      color: 'secondary',
      defaultSelected:
        selectedProductOwnershipType === ProductOwnershipType.SALE,
      onClick: () => onChangeField('ownership_type', ProductOwnershipType.SALE),
    },
    {
      testId: 'ownership-type-loaner-button',
      title: t('partner-portal.application-templates.loaner'),
      color: 'secondary',
      defaultSelected:
        selectedProductOwnershipType === ProductOwnershipType.LOANER,
      onClick: () =>
        onChangeField('ownership_type', ProductOwnershipType.LOANER),
    },
  ]

  const productSetupTypeButtons: RadioButtonsArray = [
    {
      testId: 'setup-type-payments-button',
      title: t('common.payments'),
      color: 'secondary',
      defaultSelected: selectedProductSetupType === ProductSetupType.PAYMENTS,
      onClick: () => onChangeField('setup_type', ProductSetupType.PAYMENTS),
    },
    {
      testId: 'setup-type-terminal-button',
      title: t('partner-portal.application-templates.terminal'),
      color: 'secondary',
      defaultSelected: selectedProductSetupType === ProductSetupType.TERMINAL,
      onClick: () => onChangeField('setup_type', ProductSetupType.TERMINAL),
    },
    {
      testId: 'setup-type-virtual-terminal-app-only-button',
      title: t(
        'partner-portal.application-templates.virtual-terminal-app-only'
      ),
      color: 'secondary',
      defaultSelected:
        selectedProductSetupType === ProductSetupType.VIRTUAL_TERMINAL_APP_ONLY,
      onClick: () =>
        onChangeField('setup_type', ProductSetupType.VIRTUAL_TERMINAL_APP_ONLY),
    },
  ]

  const onChangeField = function <T extends keyof AddProductData>(
    field: T,
    value: AddProductData[T]
  ) {
    setValue(field, value as any)
    if (errors[field] !== undefined) setError(field, undefined)

    // Make sure to clear the Value and Errors of Fields
    // that need to toggle from being displayed.
    const fields: (keyof AddProductData)[] = [
      'product_provider',
      'model',
      'frontend_platform',
      'backend_platform',
      'pos_system_name',
      'software_name',
      'equipment_name',
      'product_type',
      'ownership_type',
      'setup_type',
    ]

    const updatedFormData: AddProductData = { ...formData, [field]: value }

    fields.forEach((field) => {
      if (!canDisplayField[field](updatedFormData)) {
        if (updatedFormData[field] !== undefined) setValue(field, undefined)
        if (errors[field] !== undefined) setError(field, undefined)
      }
    })
    //

    // eslint-disable-next-line
    console.log({ [field]: value })
  }

  const onChangeFieldListener = function <
    T extends keyof AddProductData,
    E extends
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  >(field: T) {
    return (event: E) =>
      onChangeField(field, event.target.value as AddProductData[T])
  }

  const onCloseForm = () => {
    onClose?.()
    reset()
  }

  const onSubmit: SubmitHandler<AddProductData> = (data) => {
    // eslint-disable-next-line
    console.log({ data })
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.headerContainer}>
        <Typography
          data-testid="add-product-form-title"
          variant="h6"
          fontWeight={'bold'}
        >
          {t('partner-portal.application-templates.add-product')}
        </Typography>

        {onClose && (
          <Close
            data-testid="add-product-form-close-icon"
            className={classes.closeIcon}
            onClick={onCloseForm}
          />
        )}
      </Box>

      <form className={classes.form}>
        {canDisplayField.product_type(formData) && (
          <Controller
            name="product_type"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                testId="product-type-selector"
                label={t('partner-portal.application-templates.product-type')}
                placeholder={t(
                  'partner-portal.application-templates.select-product-type'
                )}
                options={productTypes}
                onChange={onChangeFieldListener('product_type')}
                error={Boolean(errors.product_type?.message)}
                helperText={errors.product_type?.message}
                required
              />
            )}
          />
        )}

        {canDisplayField.model(formData) && (
          <Controller
            name="model"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                testId="product-model-selector"
                label={t('partner-portal.application-templates.model')}
                placeholder={t(
                  'partner-portal.application-templates.select-product-model'
                )}
                options={productModels}
                onChange={onChangeFieldListener('model')}
                error={Boolean(errors.model?.message)}
                helperText={errors.model?.message}
                required
              />
            )}
          />
        )}

        {canDisplayField.frontend_platform(formData) && (
          <Controller
            name="frontend_platform"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                testId="frontend-platform-selector"
                label={t(
                  'partner-portal.application-templates.front-end-platform'
                )}
                placeholder={t(
                  'partner-portal.application-templates.select-front-end-platform'
                )}
                options={productFrontEndPlatforms}
                onChange={onChangeFieldListener('frontend_platform')}
                error={Boolean(errors.frontend_platform?.message)}
                helperText={errors.frontend_platform?.message}
                required
              />
            )}
          />
        )}

        {canDisplayField.backend_platform(formData) && (
          <Controller
            name="backend_platform"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                testId="backend-platform-selector"
                label={t(
                  'partner-portal.application-templates.back-end-platform'
                )}
                placeholder={t(
                  'partner-portal.application-templates.select-back-end-platform'
                )}
                options={productBackEndPlatforms}
                onChange={onChangeFieldListener('backend_platform')}
                error={Boolean(errors.backend_platform?.message)}
                helperText={errors.backend_platform?.message}
                required
              />
            )}
          />
        )}

        {canDisplayField.pos_system_name(formData) && (
          <Controller
            name="pos_system_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                testId="pos-system-name-input"
                label={t('partner-portal.application-templates.pos-system')}
                placeholder={t(
                  'partner-portal.application-templates.enter-pos-system-name'
                )}
                onChange={onChangeFieldListener('pos_system_name')}
                error={Boolean(errors.pos_system_name?.message)}
                helperText={errors.pos_system_name?.message}
                required
              />
            )}
          />
        )}

        {canDisplayField.software_name(formData) && (
          <Controller
            name="software_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                testId="software-name-input"
                label={t('partner-portal.application-templates.software')}
                placeholder={t(
                  'partner-portal.application-templates.enter-software-name'
                )}
                onChange={onChangeFieldListener('software_name')}
                error={Boolean(errors.software_name?.message)}
                helperText={errors.software_name?.message}
                required
              />
            )}
          />
        )}

        {canDisplayField.equipment_name(formData) && (
          <Controller
            name="equipment_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                testId="equipment-name-input"
                label={t('partner-portal.application-templates.equipment-name')}
                placeholder={t(
                  'partner-portal.application-templates.enter-equipment-name'
                )}
                onChange={onChangeFieldListener('equipment_name')}
                error={Boolean(errors.equipment_name?.message)}
                helperText={errors.equipment_name?.message}
                required
              />
            )}
          />
        )}

        {canDisplayField.product_provider(formData) && (
          <RadioButtons
            testId="product-provider-radio-buttons"
            label={t('partner-portal.application-templates.product-provider')}
            buttons={productProviderButtons}
            error={Boolean(errors.product_provider?.message)}
            helperText={errors.product_provider?.message}
            required
          />
        )}

        {canDisplayField.ownership_type(formData) && (
          <RadioButtons
            testId="ownership-type-radio-buttons"
            label={t('partner-portal.application-templates.ownership-type')}
            buttons={productOwnershipTypeButtons}
            error={Boolean(errors.ownership_type?.message)}
            helperText={errors.ownership_type?.message}
            required
          />
        )}

        {canDisplayField.setup_type(formData) && (
          <RadioButtons
            testId="setup-type-radio-buttons"
            label={t('partner-portal.application-templates.setup-type')}
            buttons={productSetupTypeButtons}
            error={Boolean(errors.setup_type?.message)}
            helperText={errors.setup_type?.message}
            required
          />
        )}
      </form>

      <Box className={classes.footerContainer}>
        <Button
          testId="submit-add-product-button"
          label={t('partner-portal.application-templates.add-product')}
          onClick={handleSubmit(onSubmit)}
        />
      </Box>
    </Box>
  )
}
