import { FC, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { AppBar, Box, Grid } from '@mui/material'

import { ApplicationTemplate } from '@shared/api'
import {
  Button,
  ButtonBar,
  ButtonBarEnd,
  FieldGroupContainer,
  FieldGroupRow,
  Loading,
  PageLayoutContainer,
  SelectComponent,
} from '@shared/components'
import { useFtpPortalHubCommunication, useNotification } from '@shared/hooks'

import { getTemplateInfoFieldLocalStorageKey } from '@/utils/application-templates/getTemplateInfoFieldLocalStorageKey'

import { cards } from './mocks/cardsMocks'
import { formatInputName } from './utils/formatFieldName'
import { ApplicationTypeCode } from '../application-templates-template-info/ApplicationTemplatesTemplateInfo'
import { ApplicationTemplateStepper } from '../components/application-template-stepper/ApplicationTemplateStepper'
import { PricingPlanField } from '../components/pricing-plan-field/PricingPlanField'

const useStyles = tss.withName('PricingPlan').create(() => ({
  container: {
    background: 'unset',
  },
  loadingContainer: {
    display: 'grid',
    placeContent: 'center',
    height: '80vh',
    width: '100%',
  },
  fieldGroupContainer: {
    padding: '1em',
    background: 'white',
    borderRadius: '.5em',
  },
  appBar: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
  },
}))

export const PricingPlan: FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { setNotification } = useNotification()
  const navigate = useNavigate()
  const { templateId } = useParams()

  const [applicationTemplate, setApplicationTemplate] =
    useState<ApplicationTemplate | null>(null)

  const [isLoading, setIsLoading] = useState(!applicationTemplate)

  const formMethods = useForm({
    mode: 'onSubmit',
  })

  const { watch } = formMethods

  const { pricing_plan } = watch()

  const getApplicationTemplate = async () => {
    setIsLoading(true)

    try {
      // For now Simulate an API Call,
      // later change this with the actual API Call
      const applicationTemplate = await new Promise<ApplicationTemplate>(
        (resolve) => {
          setTimeout(async () => {
            resolve({
              template_id: templateId,
              application_type_code: Number(
                localStorage.getItem(
                  getTemplateInfoFieldLocalStorageKey('application_type_code')
                )
              ) as unknown as ApplicationTypeCode,
            })
          }, 2000)
        }
      )

      setApplicationTemplate(applicationTemplate)
      setIsLoading(false)
    } catch (error) {
      setNotification({ type: 'error', label: error })
    }
  }

  const setFieldValues = () => {
    cards.map((card) => {
      card.inputs.map((input) => {
        formMethods.setValue(
          formatInputName(card.title, input.label),
          input.defaultValue
            ? input.defaultValue.toString()
            : input.value.toString()
        )
      })
    })
  }

  useEffect(() => {
    setAppBarTitle(t('partner-portal.application-templates.create-template'))
  }, [])

  useEffect(() => {
    getApplicationTemplate()
    setFieldValues()
  }, [])

  const sortedCards = cards.sort((a, b) => a.index - b.index)

  return (
    <PageLayoutContainer className={classes.container} isButtonBarAtBottom>
      {(isLoading || !applicationTemplate?.application_type_code) && (
        <Box className={classes.loadingContainer}>
          <Loading />
        </Box>
      )}

      {!isLoading && Boolean(applicationTemplate?.application_type_code) && (
        <Grid container>
          <Grid item xs={12} md={2} sx={{ padding: '24px' }}>
            <ApplicationTemplateStepper activeStep={3} />
          </Grid>

          <Grid item xs={12} md={9}>
            <FormProvider {...formMethods}>
              <form
                onSubmit={formMethods.handleSubmit((payload) => {
                  // eslint-disable-next-line no-console
                  console.log(payload)
                })}
              >
                <FieldGroupContainer
                  title={t('partner-portal.application-templates.pricing-plan')}
                  className={classes.fieldGroupContainer}
                >
                  <Controller
                    name="pricing_plan"
                    control={formMethods.control}
                    render={({ field }) => (
                      <SelectComponent
                        {...field}
                        placeholder={t(
                          'partner-portal.application-templates.select-pricing-plan'
                        )}
                        options={[
                          { label: 'Plan 1', value: 1 },
                          { label: 'Plan 2', value: 2 },
                        ]}
                        style={{ width: '50%' }}
                        testId="select-pricing-plan"
                      />
                    )}
                  />
                </FieldGroupContainer>

                {pricing_plan ? (
                  <>
                    {sortedCards?.map((card, index) => (
                      <FieldGroupContainer
                        key={index}
                        title={`${card.title} (${card.index})`}
                        className={classes.fieldGroupContainer}
                        titleTestID={`card-${card.index}`}
                      >
                        <FieldGroupRow spacing={2}>
                          {card?.inputs?.map((input, index2) => (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              key={index2}
                              data-testid={`input-${index2}-card-${card.index}`}
                            >
                              <PricingPlanField
                                cardName={card.title}
                                cardInput={input}
                              />
                            </Grid>
                          ))}
                        </FieldGroupRow>
                      </FieldGroupContainer>
                    ))}
                  </>
                ) : null}

                <AppBar className={classes.appBar}>
                  <ButtonBar style={{ marginBottom: '0 !important' }}>
                    <ButtonBarEnd>
                      <Button
                        testId="quit-button"
                        label={t('partner-portal.application-templates.quit')}
                        color="secondary"
                        style={{ marginRight: '16px' }}
                        onClick={() => {
                          navigate('/partner/application-templates')
                        }}
                      />

                      <Button
                        testId="return-button"
                        label={t('partner-portal.application-templates.return')}
                        color="secondary"
                        style={{ marginRight: '16px' }}
                        onClick={() => {
                          const mockTemplateId = localStorage.getItem(
                            getTemplateInfoFieldLocalStorageKey('template_id')
                          )

                          navigate(
                            `/partner/application-templates/${mockTemplateId}/payment-and-velocity`
                          )
                        }}
                      />

                      <Button
                        testId="submit-button"
                        type="submit"
                        label={t(
                          'partner-portal.application-templates.save-and-continue'
                        )}
                      />
                    </ButtonBarEnd>
                  </ButtonBar>
                </AppBar>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      )}
    </PageLayoutContainer>
  )
}
