import { SelectOption } from '@shared/components'

export enum InputTypes {
  PERCENT = 'Percent',
  DOLLARS = 'Dollars',
  SELECT = 'SELECT',
}

export interface Input {
  index: number
  label: string
  type: string
  value: number | string | undefined
  defaultValue: number | string | undefined
  options?: SelectOption<string>[]
  editable: boolean
}

interface Card {
  title: string
  index: number
  inputs: Input[]
}

export const cards: Card[] = [
  {
    title: 'American Express',
    index: 2,
    inputs: [
      {
        index: 1,
        label: 'Interchange Plus',
        type: InputTypes.PERCENT,
        value: 0,
        defaultValue: '',
        editable: false,
      },
      {
        index: 2,
        label: 'Authorization',
        type: InputTypes.DOLLARS,
        value: 0.05,
        defaultValue: '',
        editable: true,
      },
      {
        index: 3,
        label: 'Interchange',
        type: InputTypes.SELECT,
        value: 'interchange_3',
        defaultValue: '',
        editable: true,
        options: [
          { label: 'Interchange 1', value: 'interchange_1' },
          { label: 'Interchange 2', value: 'interchange_2' },
          { label: 'Interchange 3', value: 'interchange_3' },
        ],
      },
      {
        index: 4,
        label: 'Network Fee',
        type: InputTypes.SELECT,
        value: undefined,
        defaultValue: 'network_fee_1',
        editable: false,
        options: [
          { label: 'Network Fee 1', value: 'network_fee_1' },
          { label: 'Network Fee 2', value: 'network_fee_2' },
          { label: 'Network Fee 3', value: 'network_fee_3' },
        ],
      },
    ],
  },
  {
    title: 'Visa, Mastercard and Discover',
    index: 1,
    inputs: [
      {
        index: 1,
        label: 'Qualified',
        type: InputTypes.PERCENT,
        value: 2,
        defaultValue: '',
        editable: true,
      },
      {
        index: 2,
        label: 'Mid-Qualified Surcharge',
        type: InputTypes.PERCENT,
        value: 1,
        defaultValue: '',
        editable: false,
      },
      {
        index: 3,
        label: 'Non-Qualified Surcharge',
        type: InputTypes.PERCENT,
        value: 0,
        defaultValue: '',
        editable: true,
      },
      {
        index: 4,
        label: 'Authorization',
        type: InputTypes.DOLLARS,
        value: 0.05,
        defaultValue: '',
        editable: false,
      },
      {
        index: 5,
        label: 'Mid/Non Per Item Surcharge',
        type: InputTypes.DOLLARS,
        value: 0.25,
        defaultValue: '',
        editable: false,
      },
      {
        index: 6,
        label: 'Dues/Assessments',
        type: InputTypes.SELECT,
        value: undefined,
        defaultValue: 'dues_assessments_3',
        editable: true,
        options: [
          { label: 'Dues/Assessments 1', value: 'dues_assessments_1' },
          { label: 'Dues/Assessments 2', value: 'dues_assessments_2' },
          { label: 'Dues/Assessments 3', value: 'dues_assessments_3' },
        ],
      },
      {
        index: 7,
        label: 'Interchange Per Item',
        type: InputTypes.SELECT,
        value: 'interchange_per_item_2',
        defaultValue: '',
        editable: true,
        options: [
          { label: 'Interchange Per Item 1', value: 'interchange_per_item_1' },
          { label: 'Interchange Per Item 2', value: 'interchange_per_item_2' },
          { label: 'Interchange Per Item 3', value: 'interchange_per_item_3' },
        ],
      },
    ],
  },
  {
    title: 'PIN-Based Debit',
    index: 3,
    inputs: [
      {
        index: 1,
        label: 'Discount Rate',
        type: InputTypes.PERCENT,
        value: 0,
        defaultValue: '',
        editable: false,
      },
      {
        index: 2,
        label: 'Per Item',
        type: InputTypes.DOLLARS,
        value: 0.05,
        defaultValue: '',
        editable: true,
      },
      {
        index: 3,
        label: 'Monthly Access',
        type: InputTypes.DOLLARS,
        value: 0,
        defaultValue: '',
        editable: true,
      },
      {
        index: 4,
        label: 'Network Fees',
        type: InputTypes.DOLLARS,
        value: 9,
        defaultValue: '',
        editable: false,
      },
    ],
  },
]
