import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
  InputAmount,
  InputIntegerOnly,
  SelectComponent,
} from '@shared/components'

import {
  Input as InputInterface,
  InputTypes,
} from '../../pricing-plan/mocks/cardsMocks'
import { formatInputName } from '../../pricing-plan/utils/formatFieldName'

export interface PricingPlanFieldProps {
  cardName: string
  cardInput: InputInterface
}

export const PricingPlanField: FC<PricingPlanFieldProps> = ({
  cardName,
  cardInput,
}) => {
  const { control } = useFormContext()

  return (
    <>
      {cardInput.type === InputTypes.PERCENT && (
        <Controller
          name={formatInputName(cardName, cardInput.label)}
          control={control}
          render={({ field }) => (
            <InputIntegerOnly
              {...field}
              label={cardInput.label}
              endAdornmentText={'%'}
              disabled={!cardInput.editable}
            />
          )}
        />
      )}

      {cardInput.type === InputTypes.DOLLARS && (
        <Controller
          name={formatInputName(cardName, cardInput.label)}
          control={control}
          render={({ field }) => (
            <InputAmount
              {...field}
              style={{ width: '100%' }}
              label={cardInput.label}
              placeholder="0.00"
              disabled={!cardInput.editable}
            />
          )}
        />
      )}

      {cardInput.type === InputTypes.SELECT && (
        <Controller
          name={formatInputName(cardName, cardInput.label)}
          control={control}
          render={({ field }) => (
            <SelectComponent
              {...field}
              label={cardInput.label}
              options={cardInput.options}
              disabled={!cardInput.editable}
              style={{
                height: '44px',
              }}
            />
          )}
        />
      )}
    </>
  )
}
