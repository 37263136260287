import { api } from '@shared/api'

export const getMerchantInfo = async (merchantId: string) => {
  try {
    return await api.service('merchants').get(merchantId)
  } catch (error) {
    console.error(error)
  }
}

export const getMerchantServices = async (merchantId: string) => {
  try {
    const res = await fetch(`http://localhost:3011/services/${merchantId}`)
    const data = await res.json()
    return data.list.map((row, ind) => ({ ...row, id: ind }))
  } catch (error) {
    console.error(error)
    return []
  }
}

export const getMerchantPciStatus = async (merchantId: string) => {
  try {
    const response = await fetch(
      `http://localhost:3011/pci-status/${merchantId}`
    )
    const merchantPciStatus = await response.json()

    return merchantPciStatus[0]
  } catch (error) {
    console.error(error)
  }
}
