import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Grid, Typography } from '@mui/material'

import {
  Checkbox,
  CurrencyInput,
  FieldGroupContainer,
  FieldGroupRow,
  InputIntegerOnly,
} from '@shared/components'

import { ACHPaymentAndVelocityData } from '../cc-ach-payment-and-velocity-form/CCACHPaymentAndVelocityForm'

const useStyles = tss.withName('ACHPaymentAndVelocityFields').create(() => ({
  fieldGroupContainer: {
    padding: '1em 1em .2em 1em',
    background: 'white',
    borderRadius: '.5em',
  },
}))

export const ACHPaymentAndVelocityFields: FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext<ACHPaymentAndVelocityData>()

  return (
    <>
      <FieldGroupContainer
        title={t('partner-portal.application-templates.ach-payment-volume')}
        className={classes.fieldGroupContainer}
      >
        <Typography variant="body2" sx={{ margin: '-16px 0 24px 0' }}>
          {t(
            'partner-portal.application-details.provide-the-best-possible-estimates-of-expected-ach-activity'
          )}
        </Typography>

        <FieldGroupRow spacing={4}>
          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="ec_monthly_volume"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  testId="ec-monthly-volume-input"
                  label={t(
                    'partner-portal.application-templates.monthly-check-volume'
                  )}
                  currency={'USD'}
                  showDollarSign
                  allowDecimal={false}
                  error={!!errors.ec_monthly_volume}
                  helperText={errors.ec_monthly_volume?.message as string}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="ec_average_ticket"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  testId="ec-average-ticket-input"
                  label={t(
                    'partner-portal.application-templates.average-transaction'
                  )}
                  currency={'USD'}
                  showDollarSign
                  allowDecimal={false}
                  error={!!errors.ec_average_ticket}
                  helperText={errors.ec_average_ticket?.message as string}
                />
              )}
            />
          </Grid>
        </FieldGroupRow>

        <FieldGroupRow spacing={4}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="high_transaction"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  testId="high-transaction-input"
                  label={t(
                    'partner-portal.application-templates.high-transaction'
                  )}
                  currency={'USD'}
                  showDollarSign
                  allowDecimal={false}
                  error={!!errors.high_transaction}
                  helperText={errors.high_transaction?.message as string}
                />
              )}
            />
          </Grid>
        </FieldGroupRow>
      </FieldGroupContainer>

      <FieldGroupContainer
        title={t(
          'partner-portal.application-templates.ach-velocity-limits-request-debits'
        )}
        className={classes.fieldGroupContainer}
      >
        <FieldGroupRow spacing={4}>
          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="ec_debit_max_per_ticket"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  testId="ec-debit-max-per-ticket-input"
                  label={t('partner-portal.application-templates.max-single')}
                  currency={'USD'}
                  showDollarSign
                  allowDecimal={false}
                  error={!!errors.ec_debit_max_per_ticket}
                  helperText={errors.ec_debit_max_per_ticket?.message as string}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="ec_debit_max_daily_count"
              render={({ field }) => (
                <InputIntegerOnly
                  {...field}
                  testId="ec-debit-max-daily-count-input"
                  style={{ paddingTop: '5px' }}
                  label={t(
                    'partner-portal.application-templates.max-count-daily'
                  )}
                  placeholder="0"
                  error={!!errors.ec_debit_max_daily_count}
                  helperText={
                    errors.ec_debit_max_daily_count?.message as string
                  }
                />
              )}
            />
          </Grid>
        </FieldGroupRow>

        <FieldGroupRow spacing={4}>
          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="ec_debit_max_14day_amount"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  testId="ec-debit-max-14day-amount-input"
                  label={t(
                    'partner-portal.application-templates.max-daily-volume'
                  )}
                  currency={'USD'}
                  showDollarSign
                  allowDecimal={false}
                  error={!!errors.ec_debit_max_14day_amount}
                  helperText={
                    errors.ec_debit_max_14day_amount?.message as string
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="ec_debit_max_14day_count"
              render={({ field }) => (
                <InputIntegerOnly
                  {...field}
                  testId="ec-debit-max-14day-count-input"
                  style={{ paddingTop: '5px' }}
                  label={t(
                    'partner-portal.application-templates.max-count-in-14-days'
                  )}
                  placeholder="0"
                  error={!!errors.ec_debit_max_14day_count}
                  helperText={
                    errors.ec_debit_max_14day_count?.message as string
                  }
                />
              )}
            />
          </Grid>
        </FieldGroupRow>

        <FieldGroupRow spacing={4}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="ec_debit_max_daily_amount"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  testId="ec-debit-max-daily-amount-input"
                  label={t(
                    'partner-portal.application-templates.max-volume-in-14-days'
                  )}
                  currency={'USD'}
                  showDollarSign
                  allowDecimal={false}
                  error={!!errors.ec_debit_max_daily_amount}
                  helperText={
                    errors.ec_debit_max_daily_amount?.message as string
                  }
                />
              )}
            />
          </Grid>
        </FieldGroupRow>
      </FieldGroupContainer>

      <FieldGroupContainer
        title={t(
          'partner-portal.application-templates.ach-velocity-limits-request-credits'
        )}
        className={classes.fieldGroupContainer}
      >
        <FieldGroupRow spacing={4}>
          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="ec_credit_max_per_ticket"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  testId="ec-credit-max-per-ticket-input"
                  label={t('partner-portal.application-templates.max-single')}
                  currency={'USD'}
                  showDollarSign
                  allowDecimal={false}
                  error={!!errors.ec_credit_max_per_ticket}
                  helperText={
                    errors.ec_credit_max_per_ticket?.message as string
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="ec_credit_max_daily_count"
              render={({ field }) => (
                <InputIntegerOnly
                  {...field}
                  testId="ec-credit-max-daily-count-input"
                  style={{ paddingTop: '5px' }}
                  label={t(
                    'partner-portal.application-templates.max-count-daily'
                  )}
                  placeholder="0"
                  error={!!errors.ec_credit_max_daily_count}
                  helperText={
                    errors.ec_credit_max_daily_count?.message as string
                  }
                />
              )}
            />
          </Grid>
        </FieldGroupRow>

        <FieldGroupRow spacing={4}>
          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="ec_credit_max_daily_amount"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  testId="ec-credit-max-daily-amount-input"
                  label={t(
                    'partner-portal.application-templates.max-daily-volume'
                  )}
                  currency={'USD'}
                  showDollarSign
                  allowDecimal={false}
                  error={!!errors.ec_credit_max_daily_amount}
                  helperText={
                    errors.ec_credit_max_daily_amount?.message as string
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="ec_credit_max_14day_count"
              render={({ field }) => (
                <InputIntegerOnly
                  {...field}
                  testId="ec-credit-max-14day-count-input"
                  style={{ paddingTop: '5px' }}
                  label={t(
                    'partner-portal.application-templates.max-count-in-14-days'
                  )}
                  placeholder="0"
                  error={!!errors.ec_credit_max_14day_count}
                  helperText={
                    errors.ec_credit_max_14day_count?.message as string
                  }
                />
              )}
            />
          </Grid>
        </FieldGroupRow>

        <FieldGroupRow spacing={4}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="ec_credit_max_14day_amount"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  testId="ec-credit-max-14day-amount-input"
                  label={t(
                    'partner-portal.application-templates.max-volume-in-14-days'
                  )}
                  currency={'USD'}
                  showDollarSign
                  allowDecimal={false}
                  error={!!errors.ec_credit_max_14day_amount}
                  helperText={
                    errors.ec_credit_max_14day_amount?.message as string
                  }
                />
              )}
            />
          </Grid>
        </FieldGroupRow>
      </FieldGroupContainer>

      <FieldGroupContainer
        title={t('partner-portal.application-templates.accepted-ach-types')}
        className={classes.fieldGroupContainer}
      >
        <FieldGroupRow spacing={2}>
          <Controller
            name="ccd"
            control={control}
            render={({ field }) => (
              <Checkbox
                testId="ccd-checkbox"
                style={{ paddingLeft: '1em', marginRight: '1em' }}
                label={t('partner-portal.application-templates.ccd')}
                checked={!!field.value}
                disabled
                onChange={field.onChange}
              />
            )}
          />

          <Controller
            name="ppd"
            control={control}
            render={({ field }) => (
              <Checkbox
                testId="ppd-checkbox"
                label={t('partner-portal.application-templates.ppd')}
                style={{ marginRight: '1em' }}
                checked={!!field.value}
                disabled
                onChange={field.onChange}
              />
            )}
          />

          <Controller
            name="web"
            control={control}
            render={({ field }) => (
              <Checkbox
                testId="web-checkbox"
                label={t('partner-portal.application-templates.web')}
                style={{ marginRight: '1em' }}
                checked={!!field.value}
                onChange={field.onChange}
              />
            )}
          />

          <Controller
            name="tel"
            control={control}
            render={({ field }) => (
              <Checkbox
                testId="tel-checkbox"
                label={t('partner-portal.application-templates.tel')}
                checked={!!field.value}
                onChange={field.onChange}
              />
            )}
          />
        </FieldGroupRow>
      </FieldGroupContainer>
    </>
  )
}
