import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box } from '@mui/material'

import { Merchant } from '@shared/api/src/schemas/types'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
  HasPermission,
  PageLayoutContainerMain,
  PageLayoutContainerSide,
  PageLayoutDivider,
  UserNotAllowed,
} from '@shared/components'
import { formatPhoneNumber, formatDate } from '@shared/utils'

const useStyles = tss.withName('GeneralData').create(() => ({
  box: {
    background: '#F3F4F6',
    borderRadius: '4px',
    fontFamily: 'Inter',
    width: '100%',
  },
}))

const monthArray = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export function GeneralData({ merchant }: { merchant: Merchant }) {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const EMPTY_FIELD_PLACEHOLDER = '-'

  const getStatusDate = (statusProp: string) => {
    if (Object.keys(merchant).length > 0) {
      const timestamp = (merchant?.[statusProp] as number) * 1000
      if (!timestamp) return EMPTY_FIELD_PLACEHOLDER

      return formatDate(timestamp)
    } else {
      return EMPTY_FIELD_PLACEHOLDER
    }
  }

  const getAddressesPerType = (type: number) => {
    if (Object.keys(merchant).length > 0) {
      const addressObject = merchant?.addresses.filter(
        (address: { address_type_code?: number }) =>
          address.address_type_code === type
      )
      return addressObject.length > 0
        ? `${addressObject[0].address_line_1}, ${addressObject[0].city}, ${addressObject[0].state_province}, ${addressObject[0].postal_code}`
        : EMPTY_FIELD_PLACEHOLDER
    } else {
      return EMPTY_FIELD_PLACEHOLDER
    }
  }

  const getBankAccount = (isPrimary: boolean) => {
    if (Object.keys(merchant).length > 0) {
      const bankAccountObject = merchant?.bank_accounts.filter(
        (bankAccount: any) => bankAccount.is_primary === isPrimary
      )
      return bankAccountObject.length > 0
        ? `${bankAccountObject[0].account_number}`
        : EMPTY_FIELD_PLACEHOLDER
    } else {
      return EMPTY_FIELD_PLACEHOLDER
    }
  }

  const getMonthNames = (monthValue: number) => {
    if (!monthValue) {
      return
    }
    const monthNames = []
    for (let monthNum = 0; monthNum < 12; monthNum++) {
      if ((monthValue & (1 << monthNum)) !== 0) {
        monthNames.push(monthArray[monthNum])
      }
    }
    return monthNames.join(', ')
  }

  const seasonalMonths = getMonthNames(merchant.seasonal_months)
  const locationAddress = getAddressesPerType(1)
  const mailingAddress = getAddressesPerType(2)
  const customerServicePhone = formatPhoneNumber(
    merchant.customer_service_phone
  )
  const corporateAddress = getAddressesPerType(3)
  const phone1 = formatPhoneNumber(merchant.phone_1)
  const phone2 = formatPhoneNumber(merchant.phone_2)
  const phone3 = formatPhoneNumber(merchant.phone_3)
  const receivedTs = getStatusDate('received_ts')
  const approvedTs = getStatusDate('approved_ts')
  const installedTs = getStatusDate('installed_ts')
  const firstBatchTs = getStatusDate('first_batch_ts')
  const lastBatchTs = getStatusDate('last_batch_ts')
  const pendedTs = getStatusDate('pended_ts')
  const declinedTs = getStatusDate('declined_ts')
  const primaryBankAccount = getBankAccount(true)
  const alternateBankAccount = getBankAccount(false)

  return (
    <>
      {merchant && Object.keys(merchant).length > 0 ? (
        <HasPermission
          permission="v1.partner.merchants.get"
          unauthorizedComponent={<UserNotAllowed />}
        >
          <>
            <PageLayoutContainerMain>
              <FieldGroupContainer
                title={t('partner-portal.merchant-general-data.general-info')}
              >
                <FieldGroupRow>
                  <FieldGroupViewItem
                    title={t('partner-portal.merchant-general-data.company-id')}
                    value={merchant.company_id}
                  />

                  <FieldGroupViewItem
                    title={t('partner-portal.merchant-general-data.partner')}
                    value={merchant.agent?.dba_name}
                  />
                </FieldGroupRow>

                <FieldGroupRow>
                  <FieldGroupViewItem
                    title={t('partner-portal.merchant-general-data.mid')}
                    value={merchant.merchant_number}
                  />

                  {/* <FieldGroupViewItem
                        title="Platform"
                        value={merchant?.platform_code}
                      /> */}
                </FieldGroupRow>
              </FieldGroupContainer>

              <PageLayoutDivider />

              <FieldGroupContainer
                title={t('partner-portal.merchant-general-data.business-info')}
              >
                <FieldGroupRow>
                  <FieldGroupViewItem
                    title={t('partner-portal.merchant-general-data.dba-name')}
                    value={merchant.dba_name}
                  />
                  <FieldGroupViewItem
                    title={t(
                      'partner-portal.merchant-general-data.federal-tax-id'
                    )}
                    value={merchant.fed_tax_id}
                  />
                </FieldGroupRow>

                <FieldGroupRow>
                  <FieldGroupViewItem
                    title={t('partner-portal.merchant-general-data.corp-name')}
                    value={merchant.legal_name}
                  />
                  <FieldGroupViewItem
                    title={t(
                      'partner-portal.merchant-general-data.seasonal-months'
                    )}
                    value={seasonalMonths}
                  />
                </FieldGroupRow>

                <FieldGroupRow>
                  <FieldGroupViewItem
                    title={t(
                      'partner-portal.merchant-general-data.ownership-type'
                    )}
                    value={merchant.ownership_type}
                  />
                </FieldGroupRow>
              </FieldGroupContainer>

              <PageLayoutDivider />

              <FieldGroupContainer
                title={t(
                  'partner-portal.merchant-general-data.address-and-contact-info'
                )}
              >
                <FieldGroupRow>
                  <FieldGroupViewItem
                    title={t(
                      'partner-portal.merchant-general-data.location-address'
                    )}
                    value={locationAddress}
                  />

                  <FieldGroupViewItem
                    title={t(
                      'partner-portal.merchant-general-data.language-preference'
                    )}
                    value={merchant.language_pref}
                  />
                </FieldGroupRow>

                <FieldGroupRow>
                  <FieldGroupViewItem
                    title={t(
                      'partner-portal.merchant-general-data.mailing-address'
                    )}
                    value={mailingAddress}
                  />

                  <FieldGroupViewItem
                    title={t(
                      'partner-portal.merchant-general-data.customer-service-phone'
                    )}
                    value={customerServicePhone}
                  />
                </FieldGroupRow>

                <FieldGroupRow>
                  <FieldGroupViewItem
                    title={t(
                      'partner-portal.merchant-general-data.corportate-address'
                    )}
                    value={corporateAddress}
                  />

                  <FieldGroupViewItem
                    title={t('partner-portal.merchant-general-data.phone-1')}
                    value={phone1}
                  />
                </FieldGroupRow>

                <FieldGroupRow>
                  <FieldGroupViewItem
                    title={t('partner-portal.merchant-general-data.email')}
                    value={merchant.email}
                  />

                  <FieldGroupViewItem
                    title={t('partner-portal.merchant-general-data.phone-2')}
                    value={phone2}
                  />
                </FieldGroupRow>

                <FieldGroupRow>
                  <FieldGroupViewItem
                    title={t('partner-portal.merchant-general-data.website')}
                    value={merchant.website}
                  />

                  <FieldGroupViewItem
                    title={t('partner-portal.merchant-general-data.phone-3')}
                    value={phone3}
                  />
                </FieldGroupRow>

                <FieldGroupRow>
                  <FieldGroupViewItem
                    title={t(
                      'partner-portal.merchant-general-data.contact-name'
                    )}
                    value={merchant.contact_name}
                  />
                </FieldGroupRow>
              </FieldGroupContainer>
            </PageLayoutContainerMain>

            <PageLayoutContainerSide>
              <Box
                className={classes.box}
                sx={{
                  padding: 2,
                  marginBottom: 2,
                }}
              >
                <FieldGroupContainer
                  title={t('partner-portal.merchant-general-data.date-summary')}
                >
                  <FieldGroupRow>
                    <FieldGroupViewItem
                      title={t('partner-portal.merchant-general-data.received')}
                      value={receivedTs}
                      isFullWidth
                      isValueAlignRight
                    />
                  </FieldGroupRow>

                  <PageLayoutDivider isHalfMargin />

                  <FieldGroupRow>
                    <FieldGroupViewItem
                      title={t('partner-portal.merchant-general-data.approved')}
                      value={approvedTs}
                      isFullWidth
                      isValueAlignRight
                    />
                  </FieldGroupRow>

                  <PageLayoutDivider isHalfMargin />

                  <FieldGroupRow>
                    <FieldGroupViewItem
                      title={t(
                        'partner-portal.merchant-general-data.installed'
                      )}
                      value={installedTs}
                      isFullWidth
                      isValueAlignRight
                    />
                  </FieldGroupRow>

                  <PageLayoutDivider isHalfMargin />

                  <FieldGroupRow>
                    <FieldGroupViewItem
                      title={t(
                        'partner-portal.merchant-general-data.first-batch'
                      )}
                      value={firstBatchTs}
                      isFullWidth
                      isValueAlignRight
                    />
                  </FieldGroupRow>

                  <PageLayoutDivider isHalfMargin />

                  <FieldGroupRow>
                    <FieldGroupViewItem
                      title={t(
                        'partner-portal.merchant-general-data.last-batch'
                      )}
                      value={lastBatchTs}
                      isFullWidth
                      isValueAlignRight
                    />
                  </FieldGroupRow>

                  <PageLayoutDivider isHalfMargin />

                  <FieldGroupRow>
                    <FieldGroupViewItem
                      title={t('partner-portal.merchant-general-data.pended')}
                      value={pendedTs}
                      isFullWidth
                      isValueAlignRight
                    />
                  </FieldGroupRow>

                  <PageLayoutDivider isHalfMargin />

                  <FieldGroupRow>
                    <FieldGroupViewItem
                      title={t('partner-portal.merchant-general-data.declined')}
                      value={declinedTs}
                      isFullWidth
                      isValueAlignRight
                    />
                  </FieldGroupRow>
                </FieldGroupContainer>
              </Box>
              <Box
                className={classes.box}
                sx={{
                  padding: 2,
                }}
              >
                <FieldGroupContainer
                  title={t('partner-portal.merchant-general-data.bank-info')}
                >
                  <FieldGroupRow>
                    <FieldGroupViewItem
                      title={t(
                        'partner-portal.merchant-general-data.primary-bank-account'
                      )}
                      value={primaryBankAccount}
                      isFullWidth
                      isValueAlignRight
                    />
                  </FieldGroupRow>

                  <PageLayoutDivider isHalfMargin />

                  <FieldGroupRow>
                    <FieldGroupViewItem
                      title={t(
                        'partner-portal.merchant-general-data.alternate-bank-account'
                      )}
                      value={alternateBankAccount}
                      isFullWidth
                      isValueAlignRight
                    />
                  </FieldGroupRow>
                </FieldGroupContainer>
              </Box>
            </PageLayoutContainerSide>
          </>
        </HasPermission>
      ) : null}
    </>
  )
}
