import { noop } from 'lodash'
import { useTranslation } from 'react-i18next'

import { Stepper } from '@shared/components'

export const ApplicationTemplateStepper = ({
  activeStep,
}: {
  activeStep: number
}) => {
  const { t } = useTranslation()

  type Step = { label: string; number: number }

  const steps: Step[] = [
    {
      label: t('partner-portal.application-templates.template-info'),
      number: 0,
    },
    {
      label: t('partner-portal.application-templates.payment-velocity'),
      number: 1,
    },
    {
      label: t('common.product'),
      number: 2,
    },
    {
      label: t('partner-portal.application-templates.pricing-plan'),
      number: 3,
    },
    {
      label: t('partner-portal.application-templates.gateway-setup'),
      number: 4,
    },
  ]

  return (
    <Stepper
      steps={steps}
      activeStep={activeStep}
      testId="new-template-stepper"
      onChange={noop}
    />
  )
}
