import { Datum } from '@nivo/line'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import {
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  Paper,
  CircularProgress,
  Box,
} from '@mui/material'

import { api } from '@shared/api'
import { BaseChart, LineChart } from '@shared/components'
import {
  ChartEntity,
  Filter,
  TransactionType,
  SettledResponse,
} from '@shared/types'
import { currency } from '@shared/utils'

import mapChart from './utils/mapChart'

const useStyles = tss.withName('SettledTransactions').create(() => ({
  tooltip: {
    padding: '0.5rem',
    backgroundColor: '#4B5563',
    color: '#F3F4F6',
    fontSize: '0.75rem',
    fontFamily: 'Inter',
  },
  p: {
    margin: 0,
    padding: 0,
  },
  loadingContainer: {
    display: 'grid',
    height: '100%',
    placeContent: 'center',
  },
  selectorContainer: {
    position: 'relative',
    display: 'grid',
    placeContent: 'end',
  },
  filterTitle: {
    fontSize: '16px',
    position: 'absolute',
    top: '155%',
    right: 0,
  },
}))

const filters: Filter[] = [
  {
    id: 'last30days',
    name: 'Last 30 days',
    skipTicks: 4,
  },
]

const entities: ChartEntity[] = [
  { id: 'refund', name: 'Returns/Refunds', code: 111 },
  { id: 'sale', name: 'Sales', code: 101 },
  { id: 'credit', name: 'Credits', code: 201 },
  { id: 'debit', name: 'Debits', code: 202 },
]

const colorsByChartEntity: Record<string, string> = {
  refund: '#FFA96B',
  sale: '#4CB48E',
  credit: '#4797CB',
  debit: '#4CB48E',
}

const SettledTransactions = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const filter = 'last30days'
  const [ccTransactions, setCcTransactions] = useState<Datum[]>([])
  const [achTransactions, setAchTransactions] = useState<Datum[]>([])
  const [transactionType, setTransactionType] = useState('cc')

  const [lineTransactions, setLineTransactions] = useState<Datum | undefined>(
    undefined
  )

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const formatX = (filter: string, date: number) => {
    if (filter === 'last30days') {
      return DateTime.fromMillis(date).toFormat('MM/dd')
    }
  }

  const fetchCcData = async () => {
    try {
      setIsLoading(true)
      const ccResponse = (await api
        .service('settled-transactions')
        .get(TransactionType.CC)) as SettledResponse[]
      setCcTransactions(
        mapChart(
          filters,
          ccResponse.length ? ccResponse : [],
          entities,
          formatX
        )
      )
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setIsError(true)
      console.error(error)
    }
  }

  const fetchAchData = async () => {
    try {
      setIsLoading(true)
      const achResponse = (await api
        .service('settled-transactions')
        .get(TransactionType.ACH)) as SettledResponse[]
      setAchTransactions(
        mapChart(
          filters,
          achResponse.length ? achResponse : [],
          entities,
          formatX
        )
      )
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setIsError(true)
      console.error(error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (transactionType === 'cc') {
          await fetchCcData()
        } else if (transactionType === 'ach') {
          await fetchAchData()
        }
      } catch (error) {
        setIsLoading(false)
        setIsError(true)
        console.error(error)
      }
    }

    fetchData()
  }, [transactionType])

  useEffect(() => {
    if (
      transactionType === 'cc' &&
      ccTransactions &&
      Object.keys(ccTransactions).length > 0
    ) {
      setLineTransactions(ccTransactions[filter])
    } else if (
      transactionType === 'ach' &&
      achTransactions &&
      Object.keys(achTransactions).length > 0
    ) {
      setLineTransactions(achTransactions[filter])
    } else {
      setLineTransactions(undefined)
    }
  }, [transactionType, ccTransactions, achTransactions, filter])

  const selector = (
    <div className={classes.selectorContainer}>
      <ToggleButtonGroup
        value={transactionType}
        exclusive
        onChange={(_, value) => setTransactionType(value)}
        aria-label="text alignment"
        sx={{
          height: '2rem',
        }}
      >
        <ToggleButton value="ach">ACH</ToggleButton>
        <ToggleButton value="cc">CC</ToggleButton>
      </ToggleButtonGroup>

      <span className={classes.filterTitle}>
        {t('partner-portal.settled-transactions.last-30-days')}
      </span>
    </div>
  )

  const transactionsInfo = (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={10} md={6}>
        <div>
          <p className={classes.p}>
            {t('partner-portal.settled-transactions.total-transactions')}
          </p>
          <p className={classes.p} style={{ fontWeight: 'bold' }}>
            {lineTransactions ? lineTransactions['transactions'] : 0}
          </p>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <div>
          <p className={classes.p}>
            {t('partner-portal.settled-transactions.total-amount')}
          </p>
          <p className={classes.p} style={{ fontWeight: 'bold' }}>
            {currency(lineTransactions ? lineTransactions['amount'] : 0)}
          </p>
        </div>
      </Grid>
    </Grid>
  )

  return (
    <BaseChart
      title={t('partner-portal.settled-transactions')}
      tooltip={t('partner-portal.settled-transactions')}
      extraButton={selector}
      transactionsInfo={transactionsInfo}
    >
      {!!lineTransactions && !isLoading ? (
        <LineChart
          data={lineTransactions ? lineTransactions.data : []}
          legends={[
            {
              data: [
                {
                  id: 'refund',
                  label: t(
                    'partner-portal.settled-transactions.returns/refunds'
                  ),
                  color: colorsByChartEntity['refund'],
                },
                {
                  id: 'sale',
                  label: t('partner-portal.settled-transactions.sales'),
                  color: colorsByChartEntity['sale'],
                },
                {
                  id: 'credit',
                  label: t('partner-portal.settled-transactions.credits'),
                  color: colorsByChartEntity['credit'],
                },
                {
                  id: 'debit',
                  label: t('partner-portal.settled-transactions.debits'),
                  color: colorsByChartEntity['debit'],
                },
              ],
              itemTextColor: '#000',
              anchor: 'top-left',
              direction: 'row',
              translateX: -80,
              translateY: -20,
              itemsSpacing: 50,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              symbolShape: 'circle',
              symbolSize: 10,
            },
          ]}
          skipTicks={filters.find((f) => f.id === filter)?.skipTicks}
          tooltip={({ point }) => {
            const { data } = point
            return (
              <Paper elevation={3} className={classes.tooltip}>
                <Grid container direction={'column'}>
                  <Grid item>
                    <span>{data.xFormatted}</span>
                  </Grid>
                  <Grid item>
                    <span>{`${t(
                      'partner-portal.settled-transactions.transactions'
                    )}: ${data['transactions']}`}</span>
                  </Grid>
                  <Grid item>
                    <span>
                      {`${t(
                        'partner-portal.settled-transactions.amount'
                      )}: ${currency(Number(point.data.yFormatted))}`}
                    </span>
                  </Grid>
                </Grid>
              </Paper>
            )
          }}
        />
      ) : (
        <Box className={classes.loadingContainer}>
          {isLoading ? (
            <CircularProgress data-testid="loading-icon" size={45} />
          ) : (
            <p className={classes.p}>
              {isError
                ? t('common.validations.loading-data')
                : t('common.validations.not-available-data')}
            </p>
          )}
        </Box>
      )}
    </BaseChart>
  )
}

export default SettledTransactions
