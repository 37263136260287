import {
  ColDef,
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { noop } from 'lodash'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { AppBar } from '@mui/material'

import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { ApplicationTemplate, Page } from '@shared/api'
import { HasPermission, UserNotAllowed } from '@shared/components'
import Button from '@shared/components/buttons/Button'
import ButtonBar, {
  ButtonBarEnd,
} from '@shared/components/buttons/button-bar/ButtonBar'
import Status from '@shared/components/status/base/Status'
import { useEnforceLogin } from '@shared/hooks'
import { useFtpPortalHubCommunication } from '@shared/hooks/useFtpPortalHubCommunication'
import { formatDatetime } from '@shared/utils'

import { mapAppTypeCode, mapPlatformCode } from '@/utils/mapping'

const useStyles = tss.withName('ApplicationTemplates').create(() => ({
  appBar: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
  },
}))

export const ApplicationTemplates: FC = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()
  const navigate = useNavigate()

  // TODO: this is a temporal ag-grid Datasource
  // to consume the fake-api, use the one from shared/utils/datasource.ts
  // instead when the new endpoint is ready
  const datasource: IServerSideDatasource = useMemo(
    () => ({
      getRows: (params: IServerSideGetRowsParams) => {
        params.api.hideOverlay()

        const originalLocation = window.location.href

        fetch('http://localhost:3011/application-templates')
          .then((res) => res.json() as Promise<Page<ApplicationTemplate>>)
          .then((result) => {
            const currentLocation = window.location.href

            /* The goal of URL comparison is to avoid memory leaks warnings when we left the page before the datasource loads completely,
            destroying the grid and making it impossible for the datasource to set the values */
            if (originalLocation === currentLocation) {
              params.success({
                rowData: result.list,
                rowCount: result.pagination.total_count,
              })

              if (!result.list.length) params.api.showNoRowsOverlay()
            }
          })
          .catch(() => {
            params.success({
              rowData: [],
              rowCount: 0,
            })

            params.api.showNoRowsOverlay()
          })
      },
    }),
    []
  )

  const defaultColDef: ColDef<ApplicationTemplate, any> = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const columnDefs: ColDef<ApplicationTemplate>[] = useMemo(
    () => [
      {
        field: 'template_name',
        headerName: t('partner-portal.application-templates.title'),
        valueFormatter: (params) => params.value || '-',
      },
      {
        field: 'status_code',
        headerName: t('partner-portal.application-templates.status'),
        cellRenderer: ({ value }) => {
          const text =
            value === 0
              ? t('partner-portal.application-templates.active')
              : t('partner-portal.application-templates.deleted')

          const backgroundColor = value === 0 ? 'teal' : 'yellow'

          return <Status text={text} backgroundColor={backgroundColor} />
        },
      },
      {
        field: 'pricing_plan_name',
        headerName: t('partner-portal.application-templates.pricing-plan'),
        valueFormatter: (params) => params.value || '-',
      },
      {
        field: 'modified_ts',
        headerName: t('partner-portal.application-templates.last-edit'),
        valueGetter: (params) =>
          params.data?.modified_ts
            ? formatDatetime(params.data.modified_ts, user?.tz)
            : '-',
      },
      {
        field: 'application_type_code',
        headerName: t('partner-portal.application-templates.type'),
        valueFormatter: (params) => mapAppTypeCode(params.value),
      },
      {
        field: 'platform_code',
        headerName: t('partner-portal.application-templates.platform'),
        valueFormatter: (params) => mapPlatformCode(params.value),
      },
      {
        field: 'office_name',
        headerName: t('partner-portal.application-templates.office'),
        valueFormatter: (params) => params.value || '-',
      },
      {
        field: 'agent_name',
        headerName: t('partner-portal.application-templates.external-agent'),
        valueFormatter: (params) => params.value || '-',
      },
    ],
    [user]
  )

  useEffect(() => {
    setAppBarTitle(t('partner-portal.application-templates.templates'))
  }, [])

  const threeDotsOptions = useCallback(
    (data: ApplicationTemplate) => [
      {
        label: t('partner-portal.application-templates.view-links'),
        enabled: true,
        action: noop,
      },
      {
        label: t('partner-portal.application-templates.duplicate'),
        enabled: true,
        action: noop,
      },
      {
        label: t('partner-portal.application-templates.edit'),
        enabled: true,
        action: noop,
      },
      {
        label: t('partner-portal.application-templates.delete'),
        enabled: true,
        action: noop,
      },
    ],
    []
  )

  return (
    <HasPermission
      permission="v1.partner.applications.get" // TODO: is this the correct permission?
      unauthorizedComponent={<UserNotAllowed />}
    >
      <>
        <PaginationTable<ApplicationTemplate>
          getRowId={({ data }) => data.template_id}
          rowModelType={'serverSide'}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          serverSideDatasource={datasource}
          // serviceName={EnumServiceName.ApplicationTemplates} Add later when we have a service for it
          guidingId="application-templates"
          threeDotsOptions={threeDotsOptions}
        />

        <AppBar className={classes.appBar}>
          <ButtonBar style={{ marginBottom: '0 !important' }}>
            <ButtonBarEnd>
              <Button
                testId="new-template-button"
                label={t('partner-portal.application-templates.new-template')}
                onClick={() => navigate('/partner/application-templates/new')}
              />
            </ButtonBarEnd>
          </ButtonBar>
        </AppBar>
      </>
    </HasPermission>
  )
}
