import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import {
  AppBar,
  Backdrop,
  Box,
  Drawer,
  Grid,
  Portal,
  Typography,
} from '@mui/material'

import {
  Button,
  ButtonBar,
  ButtonBarEnd,
  PageLayoutContainer,
  PageLayoutContainerMain,
} from '@shared/components'
import { useFtpPortalHubCommunication } from '@shared/hooks'
import { Plus } from '@shared/icons'
import { removeStashedItemGroup } from '@shared/utils/local-storage-stash/localStorageStash'

import { PRODUCT_FIELD_LOCAL_STORAGE_KEY_PREFIX } from '@/utils/application-templates/getProductFieldLocalStorageKey'
import { getTemplateInfoFieldLocalStorageKey } from '@/utils/application-templates/getTemplateInfoFieldLocalStorageKey'

import { AddProductForm } from '../components/add-product-form/AddProductForm'
import { ApplicationTemplateStepper } from '../components/application-template-stepper/ApplicationTemplateStepper'

const useStyles = tss.withName('Product').create(() => ({
  container: {
    background: 'unset',
  },
  stepperContainer: {
    padding: '24px',
  },
  pageLayoutMainContainer: {
    background: 'white',
    padding: '24px',
    borderRadius: '6px',
  },
  appBar: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
  },
}))

interface ProductProps {}

const Product: FC<ProductProps> = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const navigate = useNavigate()

  const [showAddProductForm, setShowAddProductForm] = useState(false)

  const removeStashedFields = () =>
    removeStashedItemGroup(PRODUCT_FIELD_LOCAL_STORAGE_KEY_PREFIX)

  useEffect(() => {
    setAppBarTitle(t('partner-portal.application-templates.create-template'))
  }, [])

  return (
    <>
      <PageLayoutContainer className={classes.container} isButtonBarAtBottom>
        <Grid item xs={12} md={2} className={classes.stepperContainer}>
          <ApplicationTemplateStepper activeStep={2} />
        </Grid>

        <Grid item xs={12} md={9} className={classes.pageLayoutMainContainer}>
          <PageLayoutContainerMain isFullWidth>
            <Box sx={{ marginBottom: '32px' }}>
              <Typography variant="h6" sx={{ marginBottom: '8px' }}>
                {t('partner-portal.application-templates.products')}
              </Typography>

              <Typography variant="body2">
                {t(
                  'partner-portal.application-templates.after-selecting-a-product-you-will-be-able-to-specify-its-billing-and-platform-settings'
                )}
              </Typography>
            </Box>

            <Button
              testId="add-product-button"
              label={t('partner-portal.application-templates.add-product')}
              icon={<Plus />}
              iconPosition="start"
              color="secondary"
              buttonStyle={{
                height: '56px',
              }}
              onClick={() => setShowAddProductForm(true)}
            />
          </PageLayoutContainerMain>
        </Grid>

        <AppBar className={classes.appBar}>
          <ButtonBar style={{ marginBottom: '0 !important' }}>
            <ButtonBarEnd>
              <Button
                testId="quit-button"
                label={t('partner-portal.application-templates.quit')}
                color="secondary"
                style={{ marginRight: '16px' }}
                onClick={() => {
                  removeStashedFields()
                  navigate('/partner/application-templates')
                }}
              />

              <Button
                testId="submit-button"
                type="submit"
                label={t(
                  'partner-portal.application-templates.save-and-continue'
                )}
                onClick={() => {
                  // eslint-disable-next-line
                  console.log(
                    t(
                      'partner-portal.application-templates.you-need-to-add-at-least-one-product-to-proceed'
                    )
                  )

                  const mockTemplateId = localStorage.getItem(
                    getTemplateInfoFieldLocalStorageKey('template_id')
                  )

                  navigate(
                    `/partner/application-templates/${mockTemplateId}/pricing-plan`
                  )
                }}
              />
            </ButtonBarEnd>
          </ButtonBar>
        </AppBar>
      </PageLayoutContainer>

      <Portal>
        <Backdrop sx={{ zIndex: 1000 }} open={showAddProductForm}>
          <Drawer
            data-testid="add-product-form-drawer"
            open={showAddProductForm}
            variant="persistent"
            anchor={'right'}
            PaperProps={{ sx: { width: '50%', maxWidth: '640px' } }}
            onClose={() => setShowAddProductForm(false)}
          >
            <AddProductForm onClose={() => setShowAddProductForm(false)} />
          </Drawer>
        </Backdrop>
      </Portal>
    </>
  )
}

export default Product
