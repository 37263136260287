import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import {
  HasPermission,
  PageLayoutContainerMain,
  PageLayoutContainerSide,
  UserNotAllowed,
} from '@shared/components'
import { Principals } from '@shared/types'

import OwnerSection from './OnwerSection'

export function OwnerData({ principals }: { principals: Principals[] }) {
  const { t } = useTranslation()

  return (
    <>
      {principals && principals.length > 0 ? (
        <HasPermission
          permission="v1.partner.merchants.get"
          unauthorizedComponent={<UserNotAllowed />}
        >
          <PageLayoutContainerMain isFullWidth>
            {principals.map((principal, index) => (
              <Fragment key={index}>
                <OwnerSection
                  title={`${t('partner-portal.merchant-owner-data.owner')} ${
                    index + 1
                  }`}
                  principal={principal}
                />
                {index < principals.length - 1 && <PageLayoutContainerSide />}
              </Fragment>
            ))}
          </PageLayoutContainerMain>
        </HasPermission>
      ) : null}
    </>
  )
}
