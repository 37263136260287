import React from 'react'
export const DashboardIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 8.16339C8.47733 8.16339 4 12.6407 4 18.1634C4 23.6861 8.47733 28.1634 14 28.1634C19.5227 28.1634 24 23.6861 24 18.1634H14V8.16339Z"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.9998 13.4967C27.9998 8.34206 23.8212 4.16339 18.6665 4.16339V13.4967H27.9998Z"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
