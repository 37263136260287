import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Box, Grid } from '@mui/material'

import { ApplicationTemplate } from '@shared/api'
import { Loading, PageLayoutContainer } from '@shared/components'
import { useFtpPortalHubCommunication, useNotification } from '@shared/hooks'

import { getTemplateInfoFieldLocalStorageKey } from '@/utils/application-templates/getTemplateInfoFieldLocalStorageKey'

import { ApplicationTypeCode } from '../application-templates-template-info/ApplicationTemplatesTemplateInfo'
import { ApplicationTemplateStepper } from '../components/application-template-stepper/ApplicationTemplateStepper'
import { CCACHPaymentAndVelocityForm } from '../components/cc-ach-payment-and-velocity-form/CCACHPaymentAndVelocityForm'

const useStyles = tss.withName('PaymentAndVelocity').create(() => ({
  container: {
    background: 'unset',
  },
  loadingContainer: {
    display: 'grid',
    placeContent: 'center',
    height: '80vh',
    width: '100%',
  },
}))

export const PaymentAndVelocity: FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { setNotification } = useNotification()
  const { templateId } = useParams()

  const [applicationTemplate, setApplicationTemplate] =
    useState<ApplicationTemplate | null>(null)

  const [isLoading, setIsLoading] = useState(!applicationTemplate)

  const getApplicationTemplate = async () => {
    setIsLoading(true)

    try {
      // For now Simulate an API Call,
      // later change this with the actual API Call
      const applicationTemplate = await new Promise<ApplicationTemplate>(
        (resolve) => {
          setTimeout(async () => {
            resolve({
              template_id: templateId,
              application_type_code: Number(
                localStorage.getItem(
                  getTemplateInfoFieldLocalStorageKey('application_type_code')
                )
              ) as unknown as ApplicationTypeCode,
            })
          }, 2000)
        }
      )

      setApplicationTemplate(applicationTemplate)
      setIsLoading(false)
    } catch (error) {
      setNotification({ type: 'error', label: error })
    }
  }

  useEffect(() => {
    setAppBarTitle(t('partner-portal.application-templates.create-template'))
  }, [])

  useEffect(() => {
    getApplicationTemplate()
  }, [])

  return (
    <PageLayoutContainer className={classes.container} isButtonBarAtBottom>
      {(isLoading || !applicationTemplate?.application_type_code) && (
        <Box className={classes.loadingContainer}>
          <Loading />
        </Box>
      )}

      {!isLoading && Boolean(applicationTemplate?.application_type_code) && (
        <Grid container>
          <Grid item xs={12} md={2} sx={{ padding: '24px' }}>
            <ApplicationTemplateStepper activeStep={1} />
          </Grid>

          <Grid item xs={12} md={9}>
            <CCACHPaymentAndVelocityForm
              applicationTypeCode={applicationTemplate.application_type_code}
            />
          </Grid>
        </Grid>
      )}
    </PageLayoutContainer>
  )
}
