import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Tabs, Tab, AppBar } from '@mui/material'

import { HasPermission, UserNotAllowed } from '@shared/components'
import { useAuthorization, useFtpPortalHubCommunication } from '@shared/hooks'
import { checkPermission } from '@shared/utils'

// import { Equipment } from './tabs/equipment/Equipment'
import { GeneralInfo } from './tabs/general-info/GeneralInfo'
import { OwnerInfo } from './tabs/owner-info/OwnerInfo'
import { Services } from './tabs/services/Services'

const useStyles = tss.withName('MerchantDetails').create(() => ({
  tabs: {
    backgroundColor: '#FFF',
    color: '#374151',
    font: 'Inter',
    borderRadius: '2px',
    borderBottom: '1px solid #E5E7EB',
  },
}))

export default function MerchantDetails() {
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()

  const { userPermissionSet } = useAuthorization()

  const { classes } = useStyles()
  const [value, setValue] = useState(0)
  const navigate = useNavigate()
  const { id } = useParams()

  const tabStyles = {
    textTransform: 'none',
    fontSize: '14px',
    fontFamily: 'Inter',
  }

  useEffect(() => {
    setAppBarTitle(
      t('partner-portal.merchant-details.merchant-menu'),
      t('partner-portal.merchant-details.view-merchant-menu')
    )
  }, [])

  useEffect(() => {
    const url = window.location.href

    if (url.endsWith('general-info')) {
      setValue(0)
      setAppBarTitle(t('partner-portal.merchant-details.merchant-menu'), '')
    } else if (url.endsWith('owner-info')) {
      setValue(1)
      setAppBarTitle(
        t('partner-portal.merchant-details.owner-info'),
        `<Check With PM>`
      )
      return
    } else if (url.endsWith('services')) {
      setValue(2)
      setAppBarTitle(t('partner-portal.merchant-details.services'), '')
      return
    }

    /*
      TEMPORARY REMOVAL: The Equipment tab has been removed until the page is ready for production.
      When the feature is ready to be re-enabled, uncomment this block and it will
      automatically respect the proper url checks.

    if (url.endsWith('equipment')) {
      setValue(3)
      setAppBarTitle(t('partner-portal.merchant-details.equipment'), '')
      return
    }

    */
    if (url.endsWith('pci-status')) {
      setValue(4)
      setAppBarTitle(
        t('partner-portal.merchant-details.pci-status'),
        t('partner-portal.merchant-details.merchants-must-be-pci-compliant')
      )
    }
  }, [window.location.href])

  const handleChange = (_, newValue) => {
    setValue(newValue)

    let newUrl = ''
    switch (newValue) {
      case 0:
        newUrl = `/partner/merchants/${id}/general-info`
        break
      case 1:
        newUrl = `/partner/merchants/${id}/owner-info`
        break
      case 2:
        newUrl = `/partner/merchants/${id}/services`
        break
      case 3:
        newUrl = `/partner/merchants/${id}/equipment`
        break
      case 4:
        newUrl = `/partner/merchants/${id}/pci-status`
        break
      default:
        setAppBarTitle(
          t('partner-portal.merchant-details.merchant-menu'),
          t('partner-portal.merchant-details.view-merchant-menu')
        )
        break
    }

    navigate(newUrl)
  }

  const tabStyle = (index: number) => {
    return {
      ...tabStyles,
      border: value === index ? '1px solid #E5E7EB' : 'none',
      borderBottom: value === index ? 'none' : '',
      borderRadius: '6px 6px 0 0',
    }
  }

  return (
    <section>
      <HasPermission
        permission="v1.partner.merchants.get"
        unauthorizedComponent={<UserNotAllowed />}
      >
        <>
          <AppBar
            position="relative"
            elevation={0}
            sx={{ borderRadius: '4px' }}
          >
            <Tabs
              textColor="inherit"
              onChange={handleChange}
              value={value}
              className={classes.tabs}
              TabIndicatorProps={{
                style: { textTransform: 'none' },
                sx: { backgroundColor: '#D64123', fontFamily: 'Inter' },
              }}
            >
              {/*<Tab label="Overview" sx={tabStyles} />} index: 0 */}
              <Tab
                label={t('partner-portal.merchant-details.general-info')}
                sx={tabStyle(0)}
              />{' '}
              {/*index: 1 */}
              <Tab
                label={t('partner-portal.merchant-details.owner-info')}
                sx={tabStyle(1)}
              />{' '}
              {/*index: 2 */}
              <Tab
                label={t('partner-portal.merchant-details.services')}
                sx={
                  !checkPermission(
                    userPermissionSet,
                    'v1.partner.merchants.get.services'
                  )
                    ? { display: 'none' }
                    : tabStyle(2)
                }
              />
              {/*index: 3 */}
              {/* <Tab label="Schedule Fees" sx={tabStyles} /> index: 4 */}
              {/* 
              TEMPORARY REMOVAL: The Equipment tab has been removed until the page is ready for production.
              When the feature is ready to be re-enabled, uncomment this Tab component and it will
              automatically respect the proper permission checks (v1.partner.merchants.get.equipment).
              
              <Tab
                label={t('partner-portal.merchant-details.equipment')}
                sx={
                  !checkPermission(
                    userPermissionSet,
                    'v1.partner.merchants.get.equipment'
                  )
                    ? { display: 'none' }
                    : tabStyle(3)
                }
              />
              */}
              {/* index: 5 */}
              {/* <Tab label="Risk/Underwriting" sx={tabStyles} /> index: 6 */}
              {/* <Tab label="PCI Status" sx={tabStyle(4)} /> index: 7 */}
              {/* <Tab label="App History" sx={tabStyles} /> index: 8 */}
            </Tabs>
          </AppBar>

          {value === 0 && <GeneralInfo id={id} />}
          {value === 1 && <OwnerInfo id={id} />}
          {value === 2 && <Services id={id} />}
          {/* 
          TEMPORARY REMOVAL: The Equipment component rendering has been disabled
          until the page is ready for production. When re-enabling the feature,
          uncomment this line and the Equipment tab above.
          
          {value === 3 && <Equipment id={id} />}
          */}
          {/* {value === 4 && <PciStatus id={id} />} */}
        </>
      </HasPermission>
    </section>
  )
}
