import { LicenseManager } from 'ag-grid-enterprise'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { HasPermission, UserNotAllowed } from '@shared/components'
import { useFtpPortalHubCommunication } from '@shared/hooks'

import CommissionsGrid from './components/CommissionsGrid'

LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE)

export default function Commissions() {
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()

  useEffect(() => {
    setAppBarTitle(
      t('partner-portal.commissions.commission-statements'),
      t('partner-portal.commissions.view-commission-statements')
    )
  }, [])

  return (
    <section>
      <HasPermission
        permission="v1.partner.commissions.get"
        unauthorizedComponent={<UserNotAllowed />}
      >
        <CommissionsGrid />
      </HasPermission>
    </section>
  )
}
