import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { CircularProgress, Theme, useMediaQuery } from '@mui/material'

import { api } from '@shared/api/src'
import { Application } from '@shared/api/src/schemas/types'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
  HasPermission,
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutDivider,
  UserNotAllowed,
} from '@shared/components'
import AppTheme from '@shared/design'
import { useEnforceLogin, useFtpPortalHubCommunication } from '@shared/hooks'
import { AddressName } from '@shared/types'
import { currency, formatDatetime } from '@shared/utils'

import { mapAppOwnershipType } from '@/utils/mapping'

import { AppStatusStepper } from './components/AppStatusStepper'

type ApplicationAddress = Application['addresses'][number]

const useStyles = tss.withName('ApplicationDetails').create(({ theme }) => ({
  loadingContainer: {
    display: 'grid',
    placeContent: 'center',
    minHeight: '700px',
    width: '100%',
  },
}))

export default function ApplicationDetails() {
  const { t } = useTranslation()
  const { classes } = useStyles(AppTheme)
  const { id: applicationId } = useParams()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()

  const [application, setApplication] = useState<Application | null>(null)

  const isBelowMDBreakpoint = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )

  const commonInfoContainerStyles = {
    gridTemplateColumns: isBelowMDBreakpoint ? '1fr' : '1fr 1fr',
  }

  const EMPTY_FIELD_PLACEHOLDER = '-'

  const getApplication = async () => {
    try {
      await api.service('applications').get(applicationId).then(setApplication)
    } catch (error) {
      console.error(error)
    }
  }

  const getApplicationAddresses = (): Record<AddressName, string> => {
    const applicationAddresses = {
      Location: EMPTY_FIELD_PLACEHOLDER,
      Mailing: EMPTY_FIELD_PLACEHOLDER,
      Corporate: EMPTY_FIELD_PLACEHOLDER,
    }

    if (!application?.addresses?.length) return applicationAddresses

    const getAddressByType = (type: ApplicationAddress['address_type_code']) =>
      application.addresses.find(
        ({ address_type_code }) => address_type_code === type
      )

    const formatAddress = (address: ApplicationAddress | null) => {
      if (!address) return EMPTY_FIELD_PLACEHOLDER
      return `${address.address_line_1}, ${address.city}, ${address.state_province}`
    }

    applicationAddresses.Location = formatAddress(getAddressByType(1))
    applicationAddresses.Mailing = formatAddress(getAddressByType(2))
    applicationAddresses.Corporate = formatAddress(getAddressByType(3))

    return applicationAddresses
  }

  const applicationAddresses = getApplicationAddresses()

  useEffect(() => {
    setAppBarTitle(
      t('partner-portal.application-details.view-application'),
      t('partner-portal.application-details.view-application')
    )
  }, [])

  useEffect(() => {
    if (applicationId) getApplication()
  }, [applicationId])

  const createdTs = formatDatetime(application?.created_ts, user?.tz)
  const modifiedTs = formatDatetime(application?.modified_ts, user?.tz)
  const appSentTs = formatDatetime(application?.app_sent_ts, user?.tz)
  const appStartedTs = formatDatetime(application?.app_started_ts, user?.tz)
  const appReadyTs = formatDatetime(application?.app_ready_ts, user?.tz)
  const appCompletedTs = formatDatetime(application?.app_completed_ts, user?.tz)

  const ownershipType = application?.ownership_type
    ? mapAppOwnershipType(
        application.ownership_type === 'gov'
          ? 'Gov'
          : application.ownership_type.toUpperCase()
      )
    : undefined

  const ccHighTicket = application?.cc_high_ticket
    ? currency(application.cc_high_ticket).toString()
    : undefined

  const ccMonthlyVolume = application?.cc_monthly_volume
    ? currency(application.cc_monthly_volume)
    : undefined

  const ccAverageTicket = application?.cc_average_ticket
    ? currency(application.cc_average_ticket).toString()
    : undefined

  const ecHighTicket = application?.ec_high_ticket
    ? currency(application.ec_high_ticket)
    : undefined

  const ecMonthlyVolume = application?.ec_monthly_volume
    ? currency(application.ec_monthly_volume).toString()
    : undefined

  const ecAverageTicket = application?.ec_average_ticket
    ? currency(application.ec_average_ticket)
    : undefined

  const principal = !!application?.principals?.length
    ? application.principals
        .map(({ first_name, last_name }) => `${first_name} ${last_name}`)
        .join('; ')
    : undefined

  const bankAccounts = !!application?.bank_accounts?.length
    ? application.bank_accounts
        .map((bankAccount) => bankAccount.account_number)
        .join('; ')
    : undefined

  return (
    <HasPermission
      permission="v1.partner.applications.get"
      unauthorizedComponent={<UserNotAllowed />}
    >
      <PageLayoutContainer>
        {!application && (
          <div className={classes.loadingContainer}>
            <CircularProgress data-testid="loading-icon" />
          </div>
        )}

        {!!application && (
          <PageLayoutContainerMain isFullWidth>
            <FieldGroupContainer
              title={t('partner-portal.application-details.application-status')}
            >
              <AppStatusStepper statusId={application.status_code} />

              {/*checkPermission(userPermissionSet, 'v1.partner.applications.put') && (
                  <EditAppButton />
                )*/}
            </FieldGroupContainer>

            <PageLayoutDivider />

            <FieldGroupContainer
              title={t('partner-portal.application-details.application-info')}
            >
              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t('partner-portal.application-details.application-id')}
                  value={application.application_id.toString()}
                />

                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.client-application-id'
                  )}
                  value={application.client_app_id}
                />
              </FieldGroupRow>

              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t('partner-portal.application-details.agent-id')}
                  value={application.agent_id?.toString()}
                />

                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.created-timestamp'
                  )}
                  value={createdTs}
                />
              </FieldGroupRow>

              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.modified-timestamp'
                  )}
                  value={modifiedTs}
                />

                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.application-sent-timestamp'
                  )}
                  value={appSentTs}
                />
              </FieldGroupRow>

              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.application-started-timestamp'
                  )}
                  value={appStartedTs}
                />

                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.application-ready-timestamp'
                  )}
                  value={appReadyTs}
                />
              </FieldGroupRow>

              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.application-completed-timestamp'
                  )}
                  value={appCompletedTs}
                />

                <FieldGroupViewItem
                  title={t('partner-portal.application-details.company-id')}
                  value={application.company_id}
                />
              </FieldGroupRow>
            </FieldGroupContainer>

            <PageLayoutDivider />

            <FieldGroupContainer
              title={t('partner-portal.application-details.business-info')}
            >
              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t('partner-portal.application-details.legal-name')}
                  value={application.legal_name}
                />

                <FieldGroupViewItem
                  title={t('partner-portal.application-details.dba-name')}
                  value={application.dba_name}
                />
              </FieldGroupRow>

              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t('partner-portal.application-details.email')}
                  value={application.email}
                />

                <FieldGroupViewItem
                  title={t('partner-portal.application-details.mcc-code')}
                  value={application.mcc_code}
                />
              </FieldGroupRow>

              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t('partner-portal.application-details.swiped-percent')}
                  value={application.swiped_percent.toString()}
                />

                <FieldGroupViewItem
                  title={t('partner-portal.application-details.keyed-percent')}
                  value={application.keyed_percent.toString()}
                />
              </FieldGroupRow>

              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.ecommerce-percent'
                  )}
                  value={application.ecommerce_percent.toString()}
                />

                <FieldGroupViewItem
                  title={t('partner-portal.application-details.ownership-type')}
                  value={ownershipType}
                />
              </FieldGroupRow>

              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t('partner-portal.application-details.federal-tax-id')}
                  value={application.fed_tax_id}
                />

                <FieldGroupViewItem
                  title={t('partner-portal.application-details.cc-high-ticket')}
                  value={ccHighTicket}
                />
              </FieldGroupRow>

              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.cc-monthly-volume'
                  )}
                  value={ccMonthlyVolume}
                />

                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.cc-average-ticket'
                  )}
                  value={ccAverageTicket}
                />
              </FieldGroupRow>

              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t('partner-portal.application-details.ec-high-ticket')}
                  value={ecHighTicket}
                />

                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.ec-monthly-volume'
                  )}
                  value={ecMonthlyVolume}
                />
              </FieldGroupRow>

              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.ec-average-ticket'
                  )}
                  value={ecAverageTicket}
                />

                <FieldGroupViewItem
                  title={t('partner-portal.application-details.website')}
                  value={application.website}
                />
              </FieldGroupRow>

              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t('partner-portal.application-details.principals')}
                  value={principal}
                />

                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.business-address'
                  )}
                  value={applicationAddresses.Location}
                />
              </FieldGroupRow>

              <FieldGroupRow>
                <FieldGroupViewItem
                  title={t('partner-portal.application-details.bank-accounts')}
                  value={bankAccounts}
                />

                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.corporate-address'
                  )}
                  value={applicationAddresses.Corporate}
                />
              </FieldGroupRow>

              <FieldGroupRow justifyContent="flex-end">
                <FieldGroupViewItem
                  title={t(
                    'partner-portal.application-details.mailing-address'
                  )}
                  value={applicationAddresses.Mailing}
                />
              </FieldGroupRow>

              <FieldGroupRow justifyContent="flex-end">
                <FieldGroupViewItem
                  title={t('partner-portal.application-details.contact')}
                  value={application.partner_name}
                />
              </FieldGroupRow>
            </FieldGroupContainer>
          </PageLayoutContainerMain>
        )}
      </PageLayoutContainer>
    </HasPermission>
  )
}
