import { Datum } from '@nivo/line'

import { ChartEntity, Filter } from '../../types'

const PositiveTransactions = ['sale', 'debit', 'auth']
const NegativeTransactions = ['credit', 'refund']

export interface GenericTransaction {
  id?: string
  values: {
    id: string
    values: number[][]
  }[]
  range: number[]
  metrics: string[]
}

export const mapChart = (
  filters: Filter[],
  transactions: GenericTransaction[],
  chartEntities: ChartEntity[],
  formatX: (filter: string, date: number) => string
): Datum[] => {
  return filters.reduce((record, filter) => {
    const currentTransactions = transactions?.find((d) => d.id === filter.id)
    let transactionsAmount = 0
    let amount = 0

    return {
      ...record,
      [filter.id]: {
        data: currentTransactions
          ? currentTransactions.values.map((transaction) => {
              const chartEntity = chartEntities.find(
                (ch) => ch.id === transaction.id
              )
              return {
                id: chartEntity?.id,
                data: transaction.values
                  .map<Datum | undefined>((value, index) => {
                    transactionsAmount += value[0]

                    if (
                      PositiveTransactions.some(
                        (transaction) => chartEntity?.id === transaction
                      )
                    ) {
                      amount += value[1]
                    } else if (
                      NegativeTransactions.some(
                        (transaction) => chartEntity?.id === transaction
                      )
                    ) {
                      amount -= value[1]
                    }

                    const record: Datum = {
                      id: chartEntity?.name,
                      date: currentTransactions.range[index],
                      code: chartEntity?.code,
                      x: formatX(
                        filter.id,
                        currentTransactions.range[index] * 1000
                      ),
                      y: value[1],
                      transactions: value[0],
                    }

                    return record
                  })
                  .filter((record) => !!record),
              }
            })
          : [],
        transactions: transactionsAmount,
        amount: parseFloat(amount.toFixed(2)),
      },
    }
  }, [])
}
