import { startsWith } from 'lodash'

/**
 *
 * Remove a Stashed Item from the Local Storage by its Key.
 * @param key The Local Storage Key of the Stashed Item to remove.
 */
export const removeStashedItem = (key: string) => localStorage.removeItem(key)

/**
 *
 * Remove a Group of Stashed Items from Local Storage that match a Prefix Key.
 * @param prefixKey The Prefix Key of the Stashed Items to remove.
 */
export const removeStashedItemGroup = (prefixKey: string) => {
  for (const storageKey in window.localStorage) {
    if (startsWith(storageKey, prefixKey)) {
      localStorage.removeItem(storageKey)
    }
  }
}
