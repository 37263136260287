import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import ReactGA from 'react-ga4'

export const setupGoogleAnalytics = () => {
  if (process.env.REACT_GA_TRACKING) {
    ReactGA.initialize(process.env.REACT_GA_TRACKING as string)
  }
}

export const setupSentry = () => {
  if (process.env.SENTRY_DNS) {
    Sentry.init({
      dsn: process.env.SENTRY_DNS as string,
      environment: process.env.SENTRY_ENVIORMENT as string,
      integrations: [new BrowserTracing()],

      // We recommend adjusting this value in production, or using tracesSampler for finer control
      tracesSampleRate: Number(process.env.SENTRY_TRACE_SAMPLE_RATE as string),
    })
  }
}

export const setupChatBotMerchantPortal = () => {
  if (process.env.CHATBOT_MERCHANT_ID) {
    // TODO: chatbot for merchant portal?
  }
}
export const setupChatBotPartnerPortal = () => {
  if (process.env.CHATBOT_PARTNER_ID) {
    // TODO: get update from product on PP chatbot. commenting out since broken as is
    //
    // !(function (e, t, n, o, a, c) {
    //   ;(window.WebConnect = {}),
    //     ((a = t.createElement(n)).src =
    //       'https://webchat.goboomtown.com/assets/webConnectChatBox.js'),
    //     (c = t.getElementsByTagName(n)[0]).parentNode.insertBefore(a, c)
    // })(window, document, 'script'),
    //   (window.onload = function () {
    //     WebConnect.configOptions({
    //       integrationId: process.env.CHATBOT_PARTNER_ID,
    //       partnerToken: process.env.CHATBOT_TOKEN,
    //       partnerTeam: process.env.CHATBOT_TEAM,
    //       apiHost: 'https://api.goboomtown.com',
    //       frameSource: 'https://webchat.goboomtown.com',
    //     })
    //   })
  }
}
