import { DateTime, DateTimeMaybeValid } from 'luxon'

export function timestampToDate(timestamp: EpochTimeStamp) {
  return new Date(timestamp * 1000)
}

/**
 * Normalize timestamp to be in milliseconds
 * @param timestamp value in seconds or milliseconds
 * @returns value in milliseconds
 */
function normalizeDate(timestamp: number) {
  const now = new Date().getTime()

  if (Math.abs(now - timestamp) < Math.abs(now - timestamp * 1000)) {
    return timestamp
  } else {
    return timestamp * 1000
  }
}

/**
 * format any valid date to a string
 * @param date value to be formatted
 * @param formatString format to use
 * @param timezone timezone for the format
 * @returns
 */
export function formatRawDate(
  date: number | Date | string,
  formatString: string,
  timezone?: string
) {
  if (!date || !DateTime.fromJSDate(new Date(date)).isValid) return '-'

  const dateTime =
    typeof date === 'number'
      ? DateTime.fromMillis(normalizeDate(date))
      : typeof date === 'string'
      ? DateTime.fromJSDate(new Date(date))
      : DateTime.fromJSDate(date)

  const timezoneString =
    timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone

  return dateTime.setZone(timezoneString).toFormat(formatString)
}

export function formatDatetime(
  date: number | Date | string,
  timezone?: string
) {
  return formatRawDate(date, 'MM/dd/yyyy hh:mm:ss a', timezone)
}

export function formatDate(
  date: number | Date | string,
  timezone: string = 'UTC'
) {
  return formatRawDate(date, 'MM/dd/yyyy', timezone)
}

/**
 * Formats a unix timestamp to a string with the user's timezone.
 * @param unix The unix timestamp to be formatted
 * @param pattern The pattern to be used for formatting. See https://date-fns.org/v2.21.3/docs/format
 * @returns A string in the format specified by the pattern.
 */
export function formatUnixToTz(
  unix: number | null | undefined,
  pattern: string,
  tz: string
) {
  if (!unix) {
    return '-'
  }
  const luxonDate = DateTime.fromMillis(normalizeDate(unix * 1000))
  return luxonDate.setZone(tz).toFormat(pattern)
}

export const userProfileUTCDate = (date: Date, userTimezone: string) => {
  return DateTime.fromJSDate(date).setZone(userTimezone)
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const getMonthFromString = (date: string) => {
  return months[parseInt(date.toString().slice(4, 6)) - 1]
}

export const isInPast = (date: Date | string | number) => {
  if (!date) return false

  let dateFormatted: DateTimeMaybeValid
  if (typeof date === 'string') {
    dateFormatted = DateTime.fromFormat(date, 'yyyy-MM-dd')
  } else if (typeof date === 'number') {
    dateFormatted = DateTime.fromMillis(date)
  } else {
    dateFormatted = DateTime.fromJSDate(date)
  }

  if (!dateFormatted.isValid) return false

  return dateFormatted.diffNow('days').days < 0
}

/**
 * Formats a string in 'YYYYMM' format to 'Month Year', e.g., '202409' to 'September 2024'.
 * @param yearMonthString string in 'YYYYMM' format
 * @returns formatted string as 'Month Year' or '-'
 */
export function formatYearMonth(yearMonthString: string) {
  if (yearMonthString.length !== 6) return '-'
  const year = yearMonthString.substring(0, 4)
  const monthNumber = parseInt(yearMonthString.substring(4, 6))
  const date = DateTime.fromObject({ year: parseInt(year), month: monthNumber })
  return date.isValid ? date.toFormat('MMMM yyyy') : '-'
}
