import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { AppBar, Box, Grid } from '@mui/material'

import {
  Button,
  ButtonBar,
  ButtonBarEnd,
  FieldGroupContainer,
  Loading,
  PageLayoutContainer,
  SelectComponent,
} from '@shared/components'
import { useFtpPortalHubCommunication, useNotification } from '@shared/hooks'

import { getTemplateInfoFieldLocalStorageKey } from '@/utils/application-templates/getTemplateInfoFieldLocalStorageKey'

import { ApplicationTypeCode } from '../application-templates-template-info/ApplicationTemplatesTemplateInfo'
import { ApplicationTemplateStepper } from '../components/application-template-stepper/ApplicationTemplateStepper'

// Types
// TODO: This type will later be replaced with its OAS type.
type ApplicationTemplate = {
  template_id: string
  application_type_code: ApplicationTypeCode
}

const useStyles = tss.withName('PricingPlan').create(() => ({
  container: {
    background: 'unset',
  },
  loadingContainer: {
    display: 'grid',
    placeContent: 'center',
    height: '80vh',
    width: '100%',
  },
  fieldGroupContainer: {
    padding: '1em',
    background: 'white',
    borderRadius: '.5em',
  },
  appBar: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
  },
}))

type Card = {
  title: string
  index: number
}

const cards: Card[] = [
  { title: 'American Express', index: 2 },
  { title: 'Visa, Mastercard and Discover', index: 1 },
  { title: 'PIN-Based Debit', index: 3 },
]

export const PricingPlan: FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { setNotification } = useNotification()
  const navigate = useNavigate()
  const { templateId } = useParams()

  const [applicationTemplate, setApplicationTemplate] =
    useState<ApplicationTemplate | null>(null)

  const [isLoading, setIsLoading] = useState(!applicationTemplate)

  const [selectedPlan, setSelectedPlan] = useState('')

  const getApplicationTemplate = async () => {
    setIsLoading(true)

    try {
      // For now Simulate an API Call,
      // later change this with the actual API Call
      const applicationTemplate = await new Promise<ApplicationTemplate>(
        (resolve) => {
          setTimeout(async () => {
            resolve({
              template_id: templateId,
              application_type_code: Number(
                localStorage.getItem(
                  getTemplateInfoFieldLocalStorageKey('application_type_code')
                )
              ) as unknown as ApplicationTypeCode,
            })
          }, 2000)
        }
      )

      setApplicationTemplate(applicationTemplate)
      setIsLoading(false)
    } catch (error) {
      setNotification({ type: 'error', label: error })
    }
  }

  useEffect(() => {
    setAppBarTitle(t('partner-portal.application-templates.create-template'))
  }, [])

  useEffect(() => {
    getApplicationTemplate()
  }, [])

  const sortedCards = cards.sort((a, b) => a.index - b.index)

  return (
    <PageLayoutContainer className={classes.container} isButtonBarAtBottom>
      {(isLoading || !applicationTemplate?.application_type_code) && (
        <Box className={classes.loadingContainer}>
          <Loading />
        </Box>
      )}

      {!isLoading && Boolean(applicationTemplate?.application_type_code) && (
        <Grid container>
          <Grid item xs={12} md={2} sx={{ padding: '24px' }}>
            <ApplicationTemplateStepper activeStep={3} />
          </Grid>

          <Grid item xs={12} md={9}>
            <FieldGroupContainer
              title={t('partner-portal.application-templates.pricing-plan')}
              className={classes.fieldGroupContainer}
            >
              {/* Need to add a form when we have the inputs for each card */}
              <SelectComponent
                placeholder={t(
                  'partner-portal.application-templates.select-pricing-plan'
                )}
                options={[
                  { label: 'Plan 1', value: 1 },
                  { label: 'Plan 2', value: 2 },
                ]}
                value={selectedPlan}
                onChange={(event) => setSelectedPlan(event.target.value)}
                style={{ width: '50%' }}
                testId="select-pricing-plan"
              />
            </FieldGroupContainer>

            {selectedPlan ? (
              <>
                {sortedCards?.map((card, index) => (
                  <FieldGroupContainer
                    key={index}
                    title={`${card?.title} (${card?.index})`}
                    className={classes.fieldGroupContainer}
                  />
                ))}
              </>
            ) : null}

            <AppBar className={classes.appBar}>
              <ButtonBar style={{ marginBottom: '0 !important' }}>
                <ButtonBarEnd>
                  <Button
                    testId="quit-button"
                    label={t('partner-portal.application-templates.quit')}
                    color="secondary"
                    style={{ marginRight: '16px' }}
                    onClick={() => {
                      navigate('/partner/application-templates')
                    }}
                  />

                  <Button
                    testId="return-button"
                    label={t('partner-portal.application-templates.return')}
                    color="secondary"
                    style={{ marginRight: '16px' }}
                    onClick={() => {
                      const mockTemplateId = localStorage.getItem(
                        getTemplateInfoFieldLocalStorageKey('template_id')
                      )

                      navigate(
                        `/partner/application-templates/${mockTemplateId}/payment-and-velocity`
                      )
                    }}
                  />

                  <Button
                    testId="submit-button"
                    type="submit"
                    label={t(
                      'partner-portal.application-templates.save-and-continue'
                    )}
                    // eslint-disable-next-line no-console
                    onClick={() => console.log('Submit clicked')}
                  />
                </ButtonBarEnd>
              </ButtonBar>
            </AppBar>
          </Grid>
        </Grid>
      )}
    </PageLayoutContainer>
  )
}
