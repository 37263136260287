import React from 'react'
export const AdminIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66675 21.7383L13.4281 14.977C13.9494 14.4556 14.7934 14.4556 15.3134 14.977L18.1681 17.8316C18.6894 18.353 19.5334 18.353 20.0534 17.8316L28.0001 9.88629"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.4441 9.88629H28.0001V13.4423"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28 28.4583H2.56665V4.84766"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
