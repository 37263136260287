import { useTranslation } from 'react-i18next'

import { Principal } from '@shared/api'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'
import { formatPhoneNumber } from '@shared/utils'

const OwnerSection = ({
  title,
  principal,
}: {
  title: string
  principal: Principal
}) => {
  const { t } = useTranslation()

  const getPrincipalRoles = (principal: Principal) => {
    const roles = []

    if (principal.ownership_percent > 0) {
      roles.push(t('partner-portal.merchant-owner-data.owner'))
    }

    if (principal.is_signer) {
      roles.push(t('partner-portal.merchant-owner-data.signer'))
    }
    if (principal.is_controller) {
      roles.push(t('partner-portal.merchant-owner-data.controller'))
    }

    return roles.join(', ')
  }

  const phone = formatPhoneNumber(principal?.phone_number)

  const ownership = principal?.ownership_percent
    ? `${principal?.ownership_percent}%`
    : undefined

  const address = principal?.address_line_1
    ? principal?.address_line_1 + ', ' + principal?.city
    : undefined

  const roles = getPrincipalRoles(principal)

  return (
    <FieldGroupContainer title={title}>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('partner-portal.merchant-owner-data.name')}
          value={principal?.first_name}
        />

        <FieldGroupViewItem
          title={t('partner-portal.merchant-owner-data.phone')}
          value={phone}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('partner-portal.merchant-owner-data.title')}
          value={principal?.title}
        />
        <FieldGroupViewItem
          title={t('partner-portal.merchant-owner-data.email')}
          value={principal?.email_address}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('partner-portal.merchant-owner-data.ownership')}
          value={ownership}
        />

        <FieldGroupViewItem
          title={t('partner-portal.merchant-owner-data.address')}
          value={address}
        />
      </FieldGroupRow>

      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('partner-portal.merchant-owner-data.roles')}
          value={roles}
        />
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}

export default OwnerSection
