import { BarTooltipProps } from '@nivo/bar'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import {
  Box,
  Paper,
  Grid,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { api } from '@shared/api'
import { ApplicationsSubmitted } from '@shared/api/src/schemas/types'
import { BaseChart, BarChart } from '@shared/components'

import { getTotalAppsSubmitted } from './utils/getTotalAppsSubmitted'

type ChartEntityID =
  | 'approved'
  | 'in_progress'
  | 'declined'
  | 'withdrawn'
  | 'closed'

type BarData = {
  month: string
  approved: number
  in_progress: number
  declined: number
  withdrawn: number
  closed: number
}

const colorsByChartEntity: Record<ChartEntityID, string> = {
  approved: '#83bf9f',
  in_progress: '#fed85e',
  declined: '#f7955a',
  withdrawn: '#4097ca',
  closed: '#2F4AED',
}

const getBarChartData = (
  last12MonthsData: ApplicationsSubmitted
): BarData[] => {
  if (!last12MonthsData) return []
  const approved = last12MonthsData.values.find(({ id }) => id === 'approved')

  const inProgress = last12MonthsData.values.find(
    ({ id }) => id === 'in_progress'
  )

  const declined = last12MonthsData.values.find(({ id }) => id === 'declined')
  const withdrawn = last12MonthsData.values.find(({ id }) => id === 'withdrawn')
  const closed = last12MonthsData.values.find(({ id }) => id === 'closed')

  return last12MonthsData.range
    .sort((a, b) => a - b)
    .map<BarData>((range, index) => {
      const month = new Date(range * 1000).toLocaleDateString('en-US', {
        month: 'short',
      })

      const approvedQty = approved ? approved.values[index]?.[0] ?? 0 : 0

      const inProgressQty = inProgress ? inProgress.values[index]?.[0] ?? 0 : 0

      const declinedQty = declined ? declined.values[index]?.[0] ?? 0 : 0
      const withdrawnQty = withdrawn ? withdrawn.values[index]?.[0] ?? 0 : 0
      const closedQty = closed ? closed.values[index]?.[0] ?? 0 : 0

      return {
        month,
        approved: approvedQty,
        in_progress: inProgressQty,
        declined: declinedQty,
        withdrawn: withdrawnQty,
        closed: closedQty,
      }
    })
    .filter((record) => !!record)
}

const useStyles = tss.withName('AppsSubmittedByMonth').create(() => ({
  loadingContainer: {
    display: 'grid',
    height: '100%',
    placeContent: 'center',
  },
  totalAppsSubmittedTitle: {
    marginBottom: '5px',
  },
  totalAppsSubmitted: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
}))

export default function AppsSubmittedByMonth() {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const theme = useTheme()

  const [appsSubmittedByMonth, setAppsSubmittedByMonth] =
    useState<ApplicationsSubmitted | null>(null)

  const [barChartData, setBarChartData] = useState<BarData[] | null>(null)

  const totalAppsSubmitted = appsSubmittedByMonth
    ? getTotalAppsSubmitted(appsSubmittedByMonth)
    : null

  const isMobileSm = useMediaQuery(theme?.breakpoints.down('sm'))
  const desktopTitleIsWrapped = useMediaQuery('(max-width: 1551px)')

  const getBarChartMarginBottom = () => {
    if (isMobileSm) return 150
    return desktopTitleIsWrapped ? 110 : 80
  }

  const updateAppsSubmitted = (appsSubmitted: ApplicationsSubmitted[]) => {
    const [last12MonthsData] = appsSubmitted
    setAppsSubmittedByMonth(last12MonthsData)
    setBarChartData(
      getBarChartData(last12MonthsData).map((data) => ({
        ...data,
        month: t(`partner-portal.charts.${data.month.toLowerCase()}`),
      }))
    )
  }

  useEffect(() => {
    api
      .service('applications-submitted-by-month')
      .find()
      .then((appsSubmittedByMonth) => updateAppsSubmitted(appsSubmittedByMonth))
      .catch((error) => console.error(error))
  }, [])

  return (
    <BaseChart
      title={t('partner-portal.application-submitted-by-month')}
      tooltip={t(
        'partner-portal.apps-submitted-by-month.monthly-residual-amount-earned-for-the-selected-portfolio'
      )}
      transactionsInfo={
        !!totalAppsSubmitted && (
          <Box>
            <p className={classes.totalAppsSubmittedTitle}>
              {t(
                'partner-portal.apps-submitted-by-month.total-applications-submitted'
              )}
            </p>

            <span className={classes.totalAppsSubmitted}>
              {totalAppsSubmitted}
            </span>
          </Box>
        )
      }
    >
      {!barChartData && (
        <Box className={classes.loadingContainer}>
          <CircularProgress data-testid="loading-icon" size={45} />
        </Box>
      )}

      {!!barChartData && (
        <BarChart
          data={barChartData ?? []}
          keys={['approved', 'in_progress', 'declined', 'withdrawn', 'closed']}
          groupMode="stacked"
          indexBy="month"
          colors={(chartEntity) => colorsByChartEntity[chartEntity.id]}
          margin={{
            top: 50,
            bottom: getBarChartMarginBottom(),
            left: totalAppsSubmitted
              ? 8 * totalAppsSubmitted.toString().length
              : 22,
          }}
          padding={0.3}
          enableGridY={false}
          enableLabel={false}
          legends={[
            {
              dataFrom: 'keys',
              data: [
                {
                  id: 'approved',
                  label: t('partner-portal.apps-submitted-by-month.approved'),
                  color: colorsByChartEntity['approved'],
                },
                {
                  id: 'in_progress',
                  label: t(
                    'partner-portal.apps-submitted-by-month.in-progress'
                  ),
                  color: colorsByChartEntity['in_progress'],
                },
                {
                  id: 'declined',
                  label: t('partner-portal.apps-submitted-by-month.declined'),
                  color: colorsByChartEntity['declined'],
                },
                {
                  id: 'withdrawn',
                  label: t('partner-portal.apps-submitted-by-month.withdrawn'),
                  color: colorsByChartEntity['withdrawn'],
                },
                {
                  id: 'closed',
                  label: t('partner-portal.apps-submitted-by-month.closed'),
                  color: colorsByChartEntity['closed'],
                },
              ],
              itemTextColor: '#000',
              anchor: 'top-left',
              direction: 'row',
              translateX: -22,
              translateY: -35,
              itemsSpacing: 10,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              symbolShape: 'circle',
              symbolSize: 10,
            },
          ]}
          tooltip={Tooltip}
          onMouseEnter={(_datum, event: any) => {
            event.currentTarget.style.cursor = 'pointer' // Hacky way, otherwise nivo doesn't allow to change cursor
          }}
        />
      )}
    </BaseChart>
  )
}

const useTooltipStyles = tss.withName('Tooltip').create(() => ({
  tooltip: {
    padding: '10px',
    backgroundColor: '#4B5563',
    color: '#F3F4F6',
    fontSize: '14px',
  },
}))

const Tooltip: React.FC<BarTooltipProps<BarData>> = ({ data }) => {
  const { t } = useTranslation()
  const { classes } = useTooltipStyles()

  const approved = data.approved ?? 0
  const inProgress = data.in_progress ?? 0
  const declined = data.declined ?? 0
  const withdrawn = data.withdrawn ?? 0
  const closed = data.closed ?? 0

  const totalSubmitted = approved + inProgress + declined + withdrawn + closed

  return (
    <Paper elevation={3} className={classes.tooltip}>
      <Grid container direction={'column'}>
        <Grid item>
          <span>{`${t(
            'partner-portal.apps-submitted-by-month.submitted'
          )}: ${totalSubmitted}`}</span>
        </Grid>

        <Grid item>
          <span>{`${t(
            'partner-portal.apps-submitted-by-month.approved'
          )}: ${approved}`}</span>
        </Grid>

        <Grid item>
          <span>{`${t(
            'partner-portal.apps-submitted-by-month.in-progress'
          )}: ${inProgress}`}</span>
        </Grid>

        <Grid item>
          <span>{`${t(
            'partner-portal.apps-submitted-by-month.declined'
          )}: ${declined}`}</span>
        </Grid>

        <Grid item>
          <span>{`${t(
            'partner-portal.apps-submitted-by-month.withdrawn'
          )}: ${withdrawn}`}</span>
        </Grid>

        <Grid item>
          <span>Closed: {closed}</span>
        </Grid>
      </Grid>
    </Paper>
  )
}
