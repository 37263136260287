import React from 'react'
export const ReportsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.6667 28.3993V28.3993C28.14 28.3993 29.3333 27.206 29.3333 25.7326V7.06596C29.3333 5.59263 28.14 4.39929 26.6667 4.39929V4.39929C25.1933 4.39929 24 5.59263 24 7.06596V25.7326C24 27.206 25.1933 28.3993 26.6667 28.3993Z"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.9999 28.3993V28.3993C17.4733 28.3993 18.6666 27.206 18.6666 25.7327V14.6447C18.6666 13.1713 17.4733 11.978 15.9999 11.978V11.978C14.5266 11.978 13.3333 13.1713 13.3333 14.6447V25.7327C13.3333 27.206 14.5266 28.3993 15.9999 28.3993Z"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.33341 28.3993V28.3993C6.80675 28.3993 8.00008 27.2059 8.00008 25.7326V22.2233C8.00008 20.7499 6.80675 19.5566 5.33341 19.5566V19.5566C3.86008 19.5566 2.66675 20.7499 2.66675 22.2233V25.7326C2.66675 27.2059 3.86008 28.3993 5.33341 28.3993Z"
      stroke="#D64123"
      stroke-width="1.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
