export enum CurrencyCode {
  USD = 840,
  CAD = 124,
}

export const formatCurrencyCode = (code: number): string => {
  switch (code) {
    case CurrencyCode.USD:
      return 'USD'
    case CurrencyCode.CAD:
      return 'CAD'
    default:
      return '-'
  }
}
